import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { io, Socket } from 'socket.io-client';

import EnterRoom from './EnterRoom';
import { useAdsenseLoader, useAppSelector } from '../../hooks';
import ClassicPictionary from './ClassicPictionary';
import config from '../../config';
import { RoomInfo } from './types';
import EveryoneDraws from './EveryoneDraws';

const reducer = (state, action) => {
    switch (action.type) {
        case 'message':
            // console.log('Received action:', action.event)
            const newState = {
                ...state,
                events: [...state.events, action.event],
            };

            return newState;
        case 'processed':
            const updatedEvents = [...state.events];
            updatedEvents.splice(0, action.numEventsProcessed);
            return {
                ...state,
                events: updatedEvents,
            };
        default:
            console.log('Unknown action:', action);
    }
};

// const isBrowser = typeof window !== 'undefined';

interface Props extends RouteComponentProps {
    roomId?: string;
}

const Room = ({ roomId }: Props) => {
    useAdsenseLoader();
    const user = useAppSelector((state) => state.user);
    const [hasEnteredRoom, setHasEnteredRoom] = React.useState(false);
    const [roomInfo, setRoomInfo] = React.useState<RoomInfo | null>(() => {
        if (roomId === 'freedraw') {
            return {
                id: 'freedraw',
                name: 'Free Draw',
                isPrivate: false,
                maxUsers: 1,
                numUsers: 0,
                rounds: 1,
                wordList: '',
            };
        }
        return null;
    });

    // const [translate, setTranslate] = React.useState<Position>({
    //     x: 0,
    //     y: 0,
    // });
    // const [canvasDimension, setCanvasDimension] = React.useState<Dimension>({
    //     width: 1,
    //     height: 1,
    // });
    // const [calculatedSizes, setCalculatedSizes] =
    //     React.useState<CalculatedSizes>({
    //         canvasWidth: 800,
    //         canvasHeight: 600,
    //         canvasMobileWidth: 200,
    //         canvasMobileHeight: 100,
    //         gameWidth: 100,
    //         gameWidthMobile: 100,
    //         isMobile: false,
    //     });

    const [isConnected, setIsConnected] = React.useState(false);
    const [isConnecting, setIsConnecting] = React.useState(false);
    const [isObserver, setIsObserver] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [state, dispatch] = React.useReducer(reducer, {
        events: [],
    });
    const [kickReason, setKickReason] = React.useState<string | null>(null);
    const [ws, setWs] = React.useState<Socket | null>(null);
    const sessionId = useAppSelector((state) => state.user.sessionId);
    const refSessionId = React.useRef(sessionId);

    React.useEffect(() => {
        refSessionId.current = sessionId;
    }, [sessionId]);

    // React.useEffect(() => {
    //     const onLeave = (e) => {
    //         e.preventDefault();
    //         const confirmationMessage = 'Are you sure you want to leave?';

    //         (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    //         return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    //     };

    //     window.addEventListener('beforeunload', onLeave);

    //     return () => {
    //         window.removeEventListener('beforeunload', onLeave);
    //     };
    // }, []);

    React.useEffect(() => {
        if (isConnecting) {
            const isLocal = window.location.hostname === 'localhost';
            const socket = io(isLocal ? config.wssLocal : config.wssProd, {
                transports: ['websocket'],
                auth: {
                    sessionId,
                    name: !isObserver ? user.name : null,
                    avatar: !isObserver ? user.avatar : null,
                    isObserver: isObserver,
                    accessToken: user.isSignedIn ? user.accessToken : null,
                },
                query: {
                    roomId,
                },
                // closeOnBeforeunload: false,
            });

            setWs(socket);

            setIsConnected(true);
        }
    }, [isConnecting]);

    React.useEffect(() => {
        if (isConnected) {
            setIsConnecting(false);
            ws.on('connect', () => {
                console.log('Connected.');
                setIsConnected(true);
            });

            ws.onAny((eventName, ...args) => {
                if (eventName === 'error') {
                    setErrorMessage(args[0].error);
                    setIsConnected(false);
                    setHasEnteredRoom(false);
                } else if (eventName === 'kick' && args[0].name === user.name) {
                    setKickReason(args[0].reason);
                    setIsConnected(false);
                    setHasEnteredRoom(false);
                } else if (eventName === 'users') {
                    setHasEnteredRoom(true);
                }
                dispatch({
                    type: 'message',
                    event: { ...args[0], type: eventName },
                });
            });

            ws.on('disconnect', (e) => {
                console.log('Disconnected');
            });

            return () => {
                try {
                    if (ws) {
                        ws.close();
                    }
                } catch (err) {
                    console.log(err);
                }
            };
        }
    }, [isConnected, setIsConnecting]);

    if (roomId !== 'freedraw') {
        if (
            (user.name.length < 2 && !isObserver) ||
            !isConnected ||
            !hasEnteredRoom
        ) {
            return (
                <EnterRoom
                    user={user}
                    roomId={roomId}
                    errorMessage={errorMessage}
                    kickReason={kickReason}
                    roomInfo={roomInfo}
                    setRoomInfo={setRoomInfo}
                    isObserver={isObserver}
                    setIsObserver={setIsObserver}
                    isConnecting={isConnecting}
                    setIsConnecting={setIsConnecting}
                    isConnected={isConnected}
                />
            );
        }
    }

    switch (roomInfo?.gameMode || 1) {
        case 1:
        default:
            return (
                <ClassicPictionary
                    isObserver={isObserver}
                    roomId={roomId}
                    ws={ws}
                    user={user}
                    state={state}
                    dispatch={dispatch}
                    roomInfo={roomInfo}
                />
            );
        case 2:
            return (
                <EveryoneDraws
                    isObserver={isObserver}
                    roomId={roomId}
                    ws={ws}
                    user={user}
                    state={state}
                    dispatch={dispatch}
                    roomInfo={roomInfo}
                />
            );
    }
};

export default Room;
