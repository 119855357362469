import classNames from 'classnames';
import React from 'react';
import * as styles from './index.module.scss';

const pi180 = Math.PI / 180;

interface Props {
    drawTime: number;
    roundStart: number;
    roundExp: number;
    hasSomeoneFoundWord: boolean;
}

const GameClock = ({
    drawTime,
    roundStart,
    roundExp,
    hasSomeoneFoundWord,
}: Props) => {
    const [timeDiff, setTimeDiff] = React.useState(0);
    const [secondsLeft, setSecondsLeft] = React.useState<number>(0);
    const [ctx, setCtx] = React.useState<CanvasRenderingContext2D>(null);
    const refCanvas = React.useRef<HTMLCanvasElement>(null);

    React.useEffect(() => {
        const timeNow = (new Date().getTime() / 1000) | 0;

        setTimeDiff(timeNow - roundStart);
    }, [roundStart, roundExp, setTimeDiff]);

    React.useEffect(() => {
        if (refCanvas.current) {
            setCtx(refCanvas.current.getContext('2d'));
        }
    }, [refCanvas.current]);

    const scale = 3;
    React.useEffect(() => {
        if (roundExp > 0) {
            const roundTimer = setInterval(() => {
                let seconds =
                    roundExp - ((new Date().getTime() / 1000) | 0) + timeDiff;
                if (seconds < 0) seconds = 0;

                let secondsFloat =
                    roundExp - new Date().getTime() / 1000 + timeDiff;
                if (secondsFloat < 0) secondsFloat = 0;

                setSecondsLeft(Math.ceil(seconds));

                if (ctx && refCanvas.current != null) {
                    let perc = 1 - (drawTime - secondsFloat) / drawTime;

                    if (hasSomeoneFoundWord) {
                        perc = 1 - (20 - secondsFloat) / 20;
                    }

                    const radius = 20 * scale;
                    ctx.clearRect(0, 0, 50 * scale, 50 * scale);
                    ctx.lineCap = 'square';
                    ctx.lineWidth = 5 * scale;
                    if (hasSomeoneFoundWord) {
                        ctx.strokeStyle = 'rgb(255, 0, 0)';
                    } else {
                        ctx.strokeStyle = 'rgba(10, 10, 10, .6)';
                    }

                    ctx.beginPath();
                    ctx.arc(
                        refCanvas.current.width / 2,
                        refCanvas.current.width / 2,
                        radius,
                        pi180 * 270,
                        pi180 * (270 + Math.floor(perc * 360))
                    );
                    ctx.stroke();
                }
            }, 100);

            return () => {
                clearInterval(roundTimer);
            };
        }
    }, [roundExp, setSecondsLeft, timeDiff, ctx, hasSomeoneFoundWord]);

    if (roundExp < 1) {
        return null;
    }
    return (
        <>
            <canvas
                width={50 * scale}
                height={50 * scale}
                ref={refCanvas}
                className={classNames(
                    styles.gameClockCanvas,
                    secondsLeft > 0 && styles.isVisible
                )}
            />
            <h4
                className={classNames(
                    styles.seconds,
                    secondsLeft > 0 && styles.isVisible
                )}
                style={{
                    color: hasSomeoneFoundWord ? 'red' : '#333',
                    fontSize:
                        secondsLeft > 99
                            ? '.7em'
                            : secondsLeft > 9
                            ? '1em'
                            : '1.1em',
                }}
            >
                {secondsLeft}
            </h4>
        </>
    );
};

export default GameClock;
