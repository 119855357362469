import React from 'react';
import { LayerProps } from './types';

interface Props {
    zoom?: number;
    translate?: number;
    setActiveLayer: React.Dispatch<React.SetStateAction<LayerProps>>;
}

const Layer = ({
    zoom,
    translate,
    // drawLayerCanvas,
    setActiveLayer,
}) => {
    // const [ctx, setCtx] = React.useState(null)
    // const ref = React.useRef<HTMLCanvasElement>(null);

    const ref = React.useCallback((node) => {
        if (node !== null) {
            const ctx = node.getContext('2d', { willReadFrequently: true });
            ctx.webkitImageSmoothingEnabled =
                ctx.imageSmoothingEnabled =
                ctx.mozImageSmoothingEnabled =
                ctx.oImageSmoothingEnabled =
                    false;
            setActiveLayer({ canvas: node, ctx: ctx });
        }
    }, []);

    // React.useEffect(() => {
    //     if (ref.current) {
    //         const ctx = ref.current.getContext('2d');
    //         ctx.webkitImageSmoothingEnabled =
    //             ctx.imageSmoothingEnabled =
    //             ctx.mozImageSmoothingEnabled =
    //             ctx.oImageSmoothingEnabled =
    //                 false;
    //         // setCtx(ctx)
    //         console.log('setting active layer');
    //         setActiveLayer({ canvas: ref.current, ctx: ctx });
    //     }
    // }, [ref.current]);

    return <canvas ref={ref} width='800' height='600' />;
};

export default Layer;
