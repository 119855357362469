// extracted by mini-css-extract-plugin
export var winnersScreen = "index-module--winnersScreen--wC3ZK";
export var showGameFinished = "index-module--showGameFinished--bPnUp";
export var user = "index-module--user--jnIsy";
export var userDetails = "index-module--userDetails--l1+mV";
export var avatar = "index-module--avatar--tCKoo";
export var medal = "index-module--medal--xDJoU";
export var number = "index-module--number--LENJJ";
export var nr = "index-module--nr--JBk7R";
export var name = "index-module--name--0xn6H";
export var score = "index-module--score--EPAfU";