import { useEffect, useRef, useState, memo } from 'react';
import Avatar from 'avataaars';
import { AvatarStyle } from 'avataaars';
import classNames from 'classnames';
import avatarHelper from '../../../../helpers/avatarHelper';
import { MeType, ResultType, ScoreType, UserType } from '../../types';
import UserAvatar from '../../../../features/UserAvatar';
import * as styles from './index.module.scss';
import { Socket } from 'socket.io-client';

interface Props {
    // announceType: 'drawWord' | 'guessWord';
    round: number;
    rounds: number;
    drawerName?: string;
    word?: string;
    isDrawing: boolean;
    roundStart: number;
    gameFinished: boolean;
    user: MeType;
    ws: Socket;
    drawerUser?: UserType;
    hide: boolean;
    gamePhase: 'waiting' | 'drawing' | 'guessing' | 'voting' | 'result';
    // score: ScoreType[] | null;
    result: ResultType | null;
    users: UserType[];
    isObserver: boolean;
    soundEnabled: boolean;
    audioReveal: HTMLAudioElement;
    audioSmallTick: HTMLAudioElement;
    audioBlow: HTMLAudioElement;
    audioFail: HTMLAudioElement;
}

const Announcement = ({
    // isDrawing,
    word,
    drawerName,
    round,
    rounds,
    gameFinished,
    user,
    ws,
    drawerUser,
    hide,
    roundStart,
    gamePhase,
    // score,
    result,
    users,
    isObserver,
    soundEnabled,
    audioReveal,
    audioSmallTick,
    audioBlow,
    audioFail,
}: Props) => {
    const refLastWord = useRef('');
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [hideWord, setHideWord] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [showingResultNum, setShowingResultNum] = useState(-1);
    const refSoundEnabled = useRef(soundEnabled);

    useEffect(() => {
        refSoundEnabled.current = soundEnabled;
    }, [soundEnabled]);

    // const [mostVotedGuess, setMostVotedGuess] = useState<ResultType | null>(
    //     null
    // );
    // const [mostGuessed, setMostGuessed] = useState<ResultType | null>(null);
    // const [correctGuess, setCorrectGuess] = useState<ResultType | null>(null);

    useEffect(() => {
        if (word.length > 0) {
            refLastWord.current = word;
        }
    }, [word]);

    useEffect(() => {
        if (round > 0 && gamePhase !== 'waiting') {
            setIsFullScreen(true);
            // refLastWord.current = word;
        }
    }, [round, drawerName, gamePhase, word]);

    useEffect(() => {
        if (result && result.guesses.length > 0 && gamePhase === 'result') {
            let num = 0;
            let interval = setInterval(() => {
                if (num < result.guesses.length) {
                    if (refSoundEnabled.current) {
                        if (result.guesses[num].isCorrect) {
                            if (
                                result.guesses[num].voters.length === 0 &&
                                result.guesses[num].guessers.length === 0
                            ) {
                                audioFail.play();
                            } else {
                                audioReveal.play();
                            }
                        } else {
                            audioBlow.currentTime = 0;
                            audioBlow.play();
                        }
                    }
                    setShowingResultNum(num);
                    num++;
                } else {
                    clearInterval(interval);
                    interval = null;
                }
            }, 2000);

            return () => {
                if (interval) {
                    clearInterval(interval);
                }
            };
        }
    }, [result, gamePhase]);

    useEffect(() => {
        setIsVisible(
            round > 0 &&
                !gameFinished &&
                roundStart > 0 &&
                gamePhase !== 'waiting'
        );
    }, [round, gameFinished, roundStart, gamePhase]);

    useEffect(() => {
        let timeoutFullScreen = setTimeout(
            () => {
                timeoutFullScreen = null;
                setIsFullScreen(false);
            },
            gamePhase === 'result' ? 20000 : 2500
        );

        setShowingResultNum(-1);

        return () => {
            if (timeoutFullScreen) {
                clearTimeout(timeoutFullScreen);
                timeoutFullScreen = null;
            }
        };
    }, [isFullScreen, round, gamePhase]);

    const RenderWordToDraw = () => {
        if (isFullScreen) {
            if (isObserver) {
                return (
                    <>
                        <p>Players are drawing</p>
                        <small>
                            Round {round} of {rounds}
                        </small>
                    </>
                );
            }
            return (
                <>
                    <p>
                        <strong>You</strong> are drawing
                    </p>
                    <h3>{word || refLastWord.current}</h3>
                    <small>
                        Round {round} of {rounds}
                    </small>
                </>
            );
        }

        if (isObserver) {
            return <span>Players are drawing</span>;
        }

        return (
            <span onClick={() => setHideWord(!hideWord)}>
                {!hideWord ? (
                    <>{word || refLastWord.current}</>
                ) : (
                    <>Click to show</>
                )}
            </span>
        );
    };

    const RenderGuessingSession = () => {
        if (isFullScreen) {
            return (
                <>
                    <p>
                        Guess the drawing by <strong>{drawerName}</strong>
                    </p>
                    <UserAvatar user={drawerUser} className={styles.avatar} />
                </>
            );
        }
        return <span>What is this?</span>;
    };

    const RenderVotingSession = () => {
        if (isFullScreen) {
            return (
                <>
                    <p>What is the answer?</p>
                </>
            );
        }
        return <span>What is the answer?</span>;
    };

    const RenderResult = () => {
        if (isFullScreen) {
            return (
                <>
                    <div className={styles.result}>
                        <h3>Here are the results ✨</h3>

                        {result.guesses.map((r, i) => (
                            <div
                                key={r.sentence + i}
                                className={classNames(
                                    styles.most,
                                    styles[`num${i}`],
                                    showingResultNum >= i && styles.isVisible,
                                    r.isCorrect && styles.isCorrect
                                )}
                            >
                                <h4
                                    className={classNames(
                                        r.isCorrect && styles.isCorrect
                                    )}
                                >
                                    {r.sentence}
                                    {r.isCorrect && (
                                        <>
                                            <span>was the sentence!</span>
                                            {r.voters.length === 0 && (
                                                <span
                                                    className={
                                                        styles.funExtraLine
                                                    }
                                                >
                                                    but no one guessed it 😱
                                                </span>
                                            )}
                                        </>
                                    )}
                                </h4>
                                {r.guessers.length > 0 && (
                                    <div className={styles.players}>
                                        <small>Answer by{` `}</small>
                                        <div>
                                            {r.guessers.map(
                                                (guesser: string, i) => (
                                                    <span
                                                        className={
                                                            guesser ===
                                                                user.name &&
                                                            styles.isMe
                                                        }
                                                        key={guesser}
                                                    >
                                                        {guesser}
                                                    </span>
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}
                                {r.voters.length > 0 && (
                                    <div className={styles.players}>
                                        <small>Guessed by{` `}</small>
                                        <div>
                                            {r.voters.map(
                                                (voter: string, i) => (
                                                    <span
                                                        className={
                                                            voter ===
                                                                user.name &&
                                                            styles.isMe
                                                        }
                                                        key={voter}
                                                    >
                                                        {voter}
                                                    </span>
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    {users.length > 0 && users[0].score > 0 && (
                        <>
                            <p className={styles.winnerText}>
                                <small>
                                    <strong>{users[0].name}</strong> is in the
                                    1st place with {users[0].score} points!
                                </small>
                            </p>
                            <UserAvatar
                                user={users[0]}
                                className={styles.avatar}
                            />
                        </>
                    )}
                </>
            );
        }
        return <span>Next drawing coming up!</span>;
    };

    const RenderAnnouncement = () => {
        if (
            (user.name === drawerName || isObserver) &&
            gamePhase === 'drawing'
        ) {
            return RenderWordToDraw();
        }
        // return null;
        if (gamePhase === 'guessing') {
            return RenderGuessingSession();
        }
        if (gamePhase === 'voting') {
            return RenderVotingSession();
        }

        if (gamePhase === 'result') {
            return RenderResult();
        }

        return null;
    };

    if (gameFinished) {
        return null;
    }

    return (
        <div
            className={classNames(
                styles.announcement,
                isFullScreen && styles.fullScreen,
                !isFullScreen && styles.notFullScreen,
                isVisible && styles.isVisible
            )}
        >
            <div>{RenderAnnouncement()}</div>
        </div>
    );
};

export default memo(Announcement);
