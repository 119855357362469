import { useEffect, useRef, useState, memo } from 'react';
import Avatar from 'avataaars';
import { AvatarStyle } from 'avataaars';
import classNames from 'classnames';
import avatarHelper from '../../../../helpers/avatarHelper';
import { MeType, UserType } from '../../types';
import UserAvatar from '../../../../features/UserAvatar';
import * as styles from './index.module.scss';
import { Socket } from 'socket.io-client';

interface Props {
    // announceType: 'drawWord' | 'guessWord';
    round: number;
    rounds: number;
    drawerName?: string;
    word?: string;
    hint?: string;
    isDrawing: boolean;
    roundStart: number;
    gameFinished: boolean;
    user: MeType;
    isPickingWord: boolean;
    ws: Socket;
    pickableWords: string[];
    // setIsPickingWord: React.Dispatch<React.SetStateAction<boolean>>;
    numPickableWords: number;
    drawerUser?: UserType;
    hide: boolean;
}

const Announcement = ({
    // isDrawing,
    hint,
    word,
    drawerName,
    round,
    rounds,
    gameFinished,
    user,
    isPickingWord,
    // setIsPickingWord,
    pickableWords,
    ws,
    numPickableWords,
    drawerUser,
    hide,
    roundStart,
}: Props) => {
    const refLastWord = useRef('');
    const refLastHint = useRef('');
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [hideWord, setHideWord] = useState(false);

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (word.length > 0) {
            refLastWord.current = word;
        }
    }, [word]);

    useEffect(() => {
        if (round > 0) {
            setIsFullScreen(true);
            refLastWord.current = word;
            refLastHint.current = hint;
        }
    }, [round]);

    useEffect(() => {
        if (hint.length > 0) {
            refLastHint.current = hint;
        }
    }, [hint]);

    // useEffect(() => {
    //     setIsVisible(word.length > 0 || hint.length > 0);

    // }, [word, hint]);

    useEffect(() => {
        setIsVisible(round > 0 && !gameFinished && roundStart > 0);
    }, [round, gameFinished, roundStart]);

    useEffect(() => {
        let timeoutFullScreen = null;
        if (!isPickingWord && numPickableWords === 1) {
            timeoutFullScreen = setTimeout(() => {
                setIsFullScreen(false);
                timeoutFullScreen = null;
            }, 3000);
        }

        if (!isPickingWord && numPickableWords > 1) {
            setIsFullScreen(false);
        }

        return () => {
            if (timeoutFullScreen) {
                clearTimeout(timeoutFullScreen);
                timeoutFullScreen = null;
            }
        };
    }, [isFullScreen, round, isPickingWord, numPickableWords]);

    const RenderWordToDraw = () => {
        if (isFullScreen) {
            return (
                <>
                    <p>
                        <strong>You</strong> are drawing the word
                    </p>
                    <h3>{word || refLastWord.current}</h3>
                    <small>
                        Round {round} of {rounds}
                    </small>
                </>
            );
        }
        return (
            <span onClick={() => setHideWord(!hideWord)}>
                {!hideWord ? (
                    <>{word || refLastWord.current}</>
                ) : (
                    <>Click to show</>
                )}
            </span>
        );
    };

    const RenderHint = () => {
        if (isFullScreen) {
            return (
                <>
                    <p>
                        <strong>{drawerName}</strong> is drawing
                    </p>
                    <small>
                        Round {round} of {rounds}
                    </small>
                </>
            );
        }
        return (
            <span className={styles.hint}>{hint || refLastHint.current}</span>
        );
    };

    const RenderPickableWords = () => {
        if (gameFinished) {
            return null;
        }
        if (drawerName === user.name) {
            return (
                <>
                    <h3>Choose a word to draw</h3>
                    <div>
                        {pickableWords.map((word) => (
                            <button
                                key={word}
                                title={`Choose to draw ${word}`}
                                onClick={() => {
                                    ws.emit('pickWord', {
                                        type: 'pickWord',
                                        word,
                                    });
                                    // setIsPickingWord(false);
                                    setIsFullScreen(false);
                                }}
                            >
                                {word}
                            </button>
                        ))}
                    </div>
                    <div className={styles.skip}>
                        <button
                            title='Skip this round'
                            className={styles.skip}
                            onClick={() => {
                                ws.emit('skip', {
                                    type: 'skip',
                                });
                                // setIsPickingWord(false);
                                setIsFullScreen(false);
                                setIsVisible(false);
                            }}
                        >
                            skip round
                        </button>
                    </div>
                    <small>
                        Round {round} of {rounds}
                    </small>
                </>
            );
        }

        return (
            <>
                <p>
                    <strong>{drawerName}</strong> is choosing a word
                </p>
                <UserAvatar user={drawerUser} className={styles.avatar} />
            </>
        );
    };

    const RenderAnnouncement = () => {
        if (isPickingWord) {
            return <RenderPickableWords />;
        }
        if (user.name === drawerName) {
            return <RenderWordToDraw />;
        }

        return <RenderHint />;
    };

    if (gameFinished) {
        return null;
    }

    return (
        <div
            className={classNames(
                styles.announcement,
                isFullScreen && styles.fullScreen,
                !isFullScreen && styles.notFullScreen,
                isVisible && styles.isVisible
            )}
        >
            <div>
                <RenderAnnouncement />
            </div>
        </div>
    );
};

export default memo(Announcement);
