import classNames from 'classnames';
import React from 'react';
import { Socket } from 'socket.io-client';
import { Dimension, UserType } from '../../types';
import UserAvatar from '../../../../features/UserAvatar';
import Fireworks from './Fireworks';
import * as styles from './index.module.scss';

interface Props {
    gameFinished: UserType[] | null;
    setGameFinished: React.Dispatch<React.SetStateAction<UserType[]>>;
    canvasDimension: Dimension;
    ws: Socket;
    refUserFireworks: React.MutableRefObject<any[]>;
    soundEnabled: boolean;
}

const WinnersScreen = ({
    gameFinished,
    setGameFinished,
    canvasDimension,
    ws,
    refUserFireworks,
    soundEnabled,
}: Props) => {
    const [showGameFinished, setShowGameFinished] = React.useState(false);
    React.useEffect(() => {
        if (gameFinished != null) {
            setTimeout(() => {
                setShowGameFinished(true);
            }, 10);

            setTimeout(() => {
                setShowGameFinished(false);
            }, 15000);

            setTimeout(() => {
                setGameFinished(null);
            }, 15500);
        }
    }, [gameFinished]);

    const getMedal = (i) => {
        if (i === 0) return '🏆';
        if (i === 1) return '🥈';
        if (i === 2) return '🥉';
        return null;
    };

    if (gameFinished === null) return null;

    return (
        <>
            <div
                className={classNames(
                    styles.winnersScreen,
                    showGameFinished && styles.showGameFinished
                )}
                style={{
                    width: canvasDimension.width,
                    height: canvasDimension.height,
                    fontSize: canvasDimension.width / 20,
                }}
            >
                {gameFinished.slice(0, 3).map((user, index) => (
                    <div key={user.name + index} className={styles.user}>
                        <div className={styles.avatar}>
                            <UserAvatar
                                user={user}
                                style={{
                                    height:
                                        canvasDimension.height /
                                        (5 * (index * 0.6 + 1)),
                                }}
                            />
                            <span className={styles.medal}>
                                {getMedal(index)}
                            </span>
                        </div>
                        <div className={styles.userDetails}>
                            <span className={styles.nr}>
                                <i>{index + 1}.</i>
                            </span>
                            <span className={styles.name}>{user.name}</span>
                            <span className={styles.score}>
                                <i>{user.score}</i>
                            </span>
                        </div>
                    </div>
                ))}
                <Fireworks
                    soundEnabled={soundEnabled}
                    canvasDimension={canvasDimension}
                    ws={ws}
                    refUserFireworks={refUserFireworks}
                />
            </div>
        </>
    );
};

export default WinnersScreen;
