// extracted by mini-css-extract-plugin
export var isMobile = "index-module--isMobile--DPCQd";
export var voteContainer = "index-module--voteContainer--JYZqH";
export var editContainer = "index-module--editContainer--ClMLY";
export var btnCustomizeAvatar = "index-module--btnCustomizeAvatar---Mviq";
export var avatarContainer = "index-module--avatarContainer--poMTt";
export var avatarSelector = "index-module--avatarSelector--NOkhm";
export var avatar = "index-module--avatar--dDw+-";
export var room = "index-module--room--ykMj4";
export var inputArea = "index-module--inputArea--Eap7K";
export var isDrawing = "index-module--isDrawing--fO3px";
export var skip = "index-module--skip--ryu8W";
export var btnHint = "index-module--btnHint--c70Xt";
export var clearText = "index-module--clearText--g7O3L";
export var btnReportDrawer = "index-module--btnReportDrawer--FPwe7";
export var game = "index-module--game--qvlSL";
export var leftMenu = "index-module--leftMenu--TQQeF";
export var leftMenuActions = "index-module--leftMenuActions--Xwa4Z";
export var btnSound = "index-module--btnSound--R7vZJ";
export var userlist = "index-module--userlist--PuWsh";
export var viewport = "index-module--viewport--n44wb";
export var nav = "index-module--nav--+a75c";
export var hint = "index-module--hint--AMt2w";
export var isLongWord = "index-module--isLongWord--E5n88";
export var hasWord = "index-module--hasWord--KO8C-";
export var rightMenu = "index-module--rightMenu--UBuXA";
export var chatArea = "index-module--chatArea--sXzLG";
export var chat = "index-module--chat--btHQH";
export var chatAnnouncement = "index-module--chatAnnouncement--qlhp2";
export var adContainer = "index-module--adContainer--TuDDy";