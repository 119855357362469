import React from 'react';
import classNames from 'classnames';
import { Socket } from 'socket.io-client';
import Button from '../../../Button';
import * as styles from './index.module.scss';
import { AuthUserState } from '../../../../store/reducers/userSlice';

interface Props {
    guesses: string[];
    setHasVoted: any;
    hasVoted: boolean;
    myGuess: string;
    ws: Socket;
    drawerName: string;
    user: AuthUserState;
    isObserver: boolean;
}

const Votes = ({
    guesses,
    setHasVoted,
    hasVoted,
    ws,
    myGuess,
    drawerName,
    user,
    isObserver,
}: Props) => {
    const [votedGuess, setVotedGuess] = React.useState('');
    const onVote = (guess: string) => {
        ws.emit('vote', { type: 'vote', guess });
        setHasVoted(true);
        setVotedGuess(guess);
    };

    React.useEffect(() => {
        if (guesses.length === 0) {
            setVotedGuess('');
        }
    }, [guesses]);

    return (
        <div className={styles.votesContainer}>
            {drawerName === user.name || isObserver ? (
                <h3>
                    Here are the answers <small>Players are choosing one</small>
                </h3>
            ) : (
                <h3>
                    Choose an answer!{' '}
                    <small>Only one of them is correct one 🤔</small>
                </h3>
            )}
            <div
                className={classNames(
                    styles.votes,
                    guesses.length > 5 && styles.areMany
                )}
            >
                {guesses.map((guess) => (
                    <Button
                        key={guess}
                        size={
                            guesses.length > 4 || guess === myGuess
                                ? 'small'
                                : 'medium'
                        }
                        type='button'
                        color='primary'
                        onClick={() => onVote(guess)}
                        disabled={
                            guess === myGuess ||
                            drawerName === user.name ||
                            hasVoted ||
                            isObserver
                        }
                    >
                        {votedGuess === guess && '-> '}
                        {guess}
                    </Button>
                ))}
            </div>
        </div>
    );
};

export default Votes;
