import React from 'react';
import { Router } from '@reach/router';
import Room from '../../components/Room';

const RoomPage = () => {
    return (
        <Router>
            <Room path='/room/:roomId' />
        </Router>
    );
};

export default RoomPage;
