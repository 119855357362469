export function isProfanity(text: string): boolean {
    const textEdited = text.toLowerCase().split(' ').join('');

    if (textEdited === '') {
        return true;
    }

    const profanityList = [
        '@',
        '.com',
        'bitch',
        '.net',
        '.is',
        '.org',
        '.io',
        'dotnet',
        'dotcom',
        'fuckyou',
        'punkturcom',
        '.ru',
        '://',
        'asshole',
        'fuckoff',
        'fucker',
    ];
    for (const word of profanityList) {
        if (textEdited.includes(word)) {
            return true;
        }
    }
    return false;
}
