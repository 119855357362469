// extracted by mini-css-extract-plugin
export var winnersScreen = "index-module--winnersScreen--uBAuX";
export var showGameFinished = "index-module--showGameFinished--6EIq5";
export var user = "index-module--user--b1oFw";
export var userDetails = "index-module--userDetails--ZML1e";
export var avatar = "index-module--avatar--4SeDb";
export var medal = "index-module--medal--1XU-6";
export var number = "index-module--number--iK+4d";
export var nr = "index-module--nr--dIRui";
export var name = "index-module--name--2NyuE";
export var score = "index-module--score--cieFw";