import React from 'react';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import { useAppSelector } from '../../hooks';

import * as styles from './index.module.scss';

type Props = {
    showDrawingsModal: boolean;
    setShowDrawingsModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const DrawingsModal = ({ showDrawingsModal, setShowDrawingsModal }: Props) => {
    const drawings = useAppSelector((state) => state.drawing.drawings);
    const onDownload = (drawing: string) => {
        const link = document.createElement('a');
        link.download = 'drawings.png';
        link.href = drawing;
        link.click();
    };
    return (
        <div className={styles.drawingsModal}>
            <Modal
                isVisible={showDrawingsModal}
                title='Drawings'
                actions={
                    <button
                        className='button'
                        type='button'
                        onClick={() => setShowDrawingsModal(false)}
                    >
                        Close
                    </button>
                }
            >
                <div className={styles.drawings}>
                    {drawings.map((drawing, i) => (
                        <div key={i}>
                            <img src={drawing} />
                            <Button
                                onClick={() => onDownload(drawing)}
                                size='small'
                                color='primary'
                            >
                                Download
                            </Button>
                        </div>
                    ))}
                </div>
            </Modal>
        </div>
    );
};

export default DrawingsModal;
