import axios from 'axios';
import { Link, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import React from 'react';
import GameContainer from '../../GameContainer';
import PageLayout from '../../PageLayout';
import CustomizeAvatar from '../../../features/CustomizeAvatar';
import { AuthUserState, setName } from '../../../store/reducers/userSlice';
import config from '../../../config';
import UserCustomize from '../../../features/UserCustomize';
import Button from '../../Button';
import { RoomInfo } from '../types';
import { getWordListInfo } from '../../../helpers/roomHelper';
import * as styles from './index.module.scss';

interface Props {
    setIsConnecting: React.Dispatch<React.SetStateAction<boolean>>;
    isConnecting: boolean;
    user: AuthUserState;
    roomId: string;
    errorMessage: string;
    kickReason: string;
    roomInfo: RoomInfo;
    setRoomInfo: React.Dispatch<RoomInfo>;
    setIsObserver: React.Dispatch<React.SetStateAction<boolean>>;
    isObserver: boolean;
    isConnected: boolean;
}

const EnterRoom = ({
    setIsConnecting,
    user,
    roomId,
    errorMessage,
    kickReason,
    roomInfo,
    setRoomInfo,
    isObserver,
    setIsObserver,
    isConnecting,
    isConnected,
}: Props) => {
    const [isFetching, setIsFetching] = React.useState(true);

    const [isCustomizeAvatarEnabled, setIsCustomizeAvatarEnabled] =
        React.useState(false);

    const onSubmit = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        setIsConnecting(true);
    };

    React.useEffect(() => {
        if (isFetching && roomId.length > 0) {
            const isLocal = window.location.hostname === 'localhost';
            const getRoomAsync = async () => {
                try {
                    const res = await axios.get(
                        `${
                            isLocal ? config.gameApiLocal : config.gameApiProd
                        }/room/${roomId}`
                    );

                    if (res.data) {
                        setRoomInfo(res.data);
                    }
                } catch (err) {
                    console.log(err);
                    console.log(err);
                }
                setIsFetching(false);
            };

            getRoomAsync();
        }
    }, [isFetching, roomId]);

    const getTitle = () => {
        if (isFetching) {
            return 'Please wait - Skissan';
        }
        if (roomInfo != null) {
            return `${roomInfo.name} - Skissan`;
        }
        return 'New room - Skissan';
    };

    if (kickReason != null) {
        return (
            <PageLayout>
                <Helmet>
                    <title>You were kicked out - Skissan</title>
                </Helmet>
                <img src='/skissan.svg' alt='Skissan' />
                <h2>You have left the room</h2>
                <GameContainer>
                    <h3>You were kicked out</h3>
                    <p>
                        {kickReason?.length > 0 ? (
                            <i>{kickReason}</i>
                        ) : (
                            <i>No reason given</i>
                        )}
                    </p>
                </GameContainer>
                <div style={{ marginTop: '2rem' }}>
                    <Link to='/games'>← Back to games list</Link>
                </div>
                {roomInfo != null && roomInfo.version != null && (
                    <div
                        style={{
                            textAlign: 'center',
                            marginTop: '2rem',
                            opacity: 0.5,
                            fontSize: '.8rem',
                        }}
                    >
                        Server version: {roomInfo.version}
                    </div>
                )}
            </PageLayout>
        );
    }

    if (!isFetching && roomInfo == null) {
        return (
            <PageLayout>
                <Helmet>
                    <title>Room not found - Skissan</title>
                </Helmet>
                <img src='/skissan.svg' alt='Skissan' />
                <h2>Room was not found</h2>
                <GameContainer>
                    <h3>
                        The room you tried to enter does not exist any longer.
                    </h3>
                    <Button
                        onClick={() => navigate('/games')}
                        size='medium'
                        color='primary'
                    >
                        Games
                    </Button>
                </GameContainer>
                <div style={{ marginTop: '2rem' }}>
                    <Link to='/games'>← Back to games list</Link>
                </div>
                {roomInfo != null && roomInfo.version != null && (
                    <div
                        style={{
                            textAlign: 'center',
                            marginTop: '2rem',
                            opacity: 0.5,
                            fontSize: '.8rem',
                        }}
                    >
                        Server version: {roomInfo.version}
                    </div>
                )}
            </PageLayout>
        );
    }

    return (
        <PageLayout>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <img src='/skissan.svg' alt='Skissan' />
            <div className={styles.enterNameContainer}>
                <h2>
                    {isFetching && 'Fetching room details, please wait...'}
                    {!isFetching && roomInfo != null && (
                        <>
                            Entering the room <i>{roomInfo.name}</i>
                        </>
                    )}
                    {!isFetching && roomInfo == null && <>Room was not found</>}
                </h2>
                <GameContainer className={styles.enterRoom}>
                    <form onSubmit={onSubmit}>
                        {!isFetching && roomInfo != null ? (
                            <p>
                                This room has{' '}
                                <strong>{roomInfo.numUsers}</strong> user
                                {roomInfo.numUsers > 0 ? 's' : ''} and the
                                wordlist is{' '}
                                <strong>{getWordListInfo(roomInfo)}</strong>
                            </p>
                        ) : (
                            <p>
                                {isFetching
                                    ? 'Fetching room details, please wait'
                                    : 'Room not found'}
                            </p>
                        )}

                        <div className={styles.observer}>
                            <label>
                                <input
                                    type='checkbox'
                                    checked={isObserver}
                                    onChange={() => setIsObserver(!isObserver)}
                                />{' '}
                                Watch game as an observer or streamer 📺
                            </label>
                        </div>
                        <div className={styles.split}>
                            <div className={styles.leftSide}>
                                {!isCustomizeAvatarEnabled ? (
                                    <>
                                        {!isObserver && (
                                            <>
                                                <UserCustomize
                                                    setIsCustomizeAvatarEnabled={
                                                        setIsCustomizeAvatarEnabled
                                                    }
                                                    isCustomizeAvatarEnabled={
                                                        isCustomizeAvatarEnabled
                                                    }
                                                />
                                            </>
                                        )}

                                        {errorMessage != null && (
                                            <p className={styles.error}>
                                                {errorMessage}
                                            </p>
                                        )}
                                    </>
                                ) : (
                                    <CustomizeAvatar
                                        setIsCustomizeAvatarEnabled={
                                            setIsCustomizeAvatarEnabled
                                        }
                                    />
                                )}
                            </div>
                            {!isCustomizeAvatarEnabled && (
                                <div className={styles.rightSide}>
                                    <div className={styles.btnContainer}>
                                        {isConnecting || isConnected ? (
                                            <p>
                                                Connecting to room... please
                                                wait!
                                            </p>
                                        ) : (
                                            <div>
                                                <Button
                                                    type='submit'
                                                    size='large'
                                                    color='primary'
                                                    disabled={
                                                        (user.name.length < 2 &&
                                                            !isObserver) ||
                                                        isConnecting
                                                    }
                                                >
                                                    Join room
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </GameContainer>
                <div style={{ marginTop: '2rem' }}>
                    <Link to='/games'>← Back to games list</Link>
                </div>
            </div>
            {roomInfo != null && roomInfo.version != null && (
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: '2rem',
                        opacity: 0.5,
                        fontSize: '.8rem',
                    }}
                >
                    Server version: {roomInfo.version}
                </div>
            )}
        </PageLayout>
    );
};

export default EnterRoom;
