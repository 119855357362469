import React from 'react';
import Avatar, { AvatarStyle } from 'avataaars';
import avatarHelper from '../../../../helpers/avatarHelper';
import * as styles from './index.module.scss';
import { UserType } from '../../types';

interface Props {
    isMe: boolean;
    user: UserType;
}

const UserDetails = ({ user, isMe }: Props) => {
    if (user.isDrawing) {
        return (
            <div className={styles.nameDetails}>
                <div>
                    <strong>{user.name}</strong>
                </div>{' '}
                <span style={{ color: 'rgb(65 179 58)' }}>✏️</span>
            </div>
        );
    }

    if (user.hasFoundWord) {
        return (
            <div
                className={styles.nameDetails}
                style={{ color: 'rgb(65 179 58)', fontWeight: 'bold' }}
            >
                <div>{user.name}</div> <span>✔️</span>
            </div>
        );
    }

    return (
        <div className={styles.nameDetails}>
            {isMe ? (
                <div>
                    <strong>{user.name}</strong>
                </div>
            ) : (
                <div>{user.name}</div>
            )}
        </div>
    );
};

const User = ({ user, isMe }: Props) => {
    return (
        <div
            className={styles.user}
            style={{
                fontWeight: isMe ? 'bold' : 'normal',
                backgroundColor: user.hasFoundWord && '#93ff8d52',
            }}
        >
            <Avatar
                className={styles.avatar}
                avatarStyle={AvatarStyle.Transparent}
                accessoriesType={
                    avatarHelper.accessoriesType[
                        user.avatar[avatarHelper.typeIndex.accessories]
                    ]
                }
                topType={
                    avatarHelper.topType[
                        user.avatar[avatarHelper.typeIndex.top]
                    ]
                }
                eyebrowType={
                    avatarHelper.eyebrowType[
                        user.avatar[avatarHelper.typeIndex.eyeBrow]
                    ]
                }
                eyeType={
                    avatarHelper.eyeType[
                        user.avatar[avatarHelper.typeIndex.eye]
                    ]
                }
                mouthType={
                    avatarHelper.mouthType[
                        user.avatar[avatarHelper.typeIndex.mouth]
                    ]
                }
                clotheType={
                    avatarHelper.clotheType[
                        user.avatar[avatarHelper.typeIndex.clothe]
                    ]
                }
                facialHairType={
                    avatarHelper.facialHairType[
                        user.avatar[avatarHelper.typeIndex.facialHair]
                    ]
                }
                hairColor={
                    avatarHelper.hairColor[
                        user.avatar[avatarHelper.typeIndex.hairColor]
                    ]
                }
                clotheColor={
                    avatarHelper.clotheColor[
                        user.avatar[avatarHelper.typeIndex.clotheColor]
                    ]
                }
                facialHairColor={
                    avatarHelper.facialHairColor[
                        user.avatar[avatarHelper.typeIndex.facialHairColor]
                    ]
                }
                skinColor={
                    avatarHelper.skinColor[
                        user.avatar[avatarHelper.typeIndex.skinColor]
                    ]
                }
                hatColor={
                    avatarHelper.hatColor[
                        user.avatar[avatarHelper.typeIndex.hatColor]
                    ]
                }
                graphicType={
                    avatarHelper.graphicType[
                        user.avatar[avatarHelper.typeIndex.graphic]
                    ]
                }
            />
            <div className={styles.userInfo}>
                <UserDetails isMe={isMe} user={user} />
                <div>{user.score}</div>
            </div>
        </div>
    );
};

export default React.memo(User);
