import React from 'react';
import { Socket } from 'socket.io-client';
import { Dimension, Position } from '../../types';

const isBrowser = typeof window !== 'undefined';

let exp1: HTMLAudioElement;
let exp2: HTMLAudioElement;
let exp3: HTMLAudioElement;

let lastSound = 0;

if (isBrowser) {
    exp1 = new Audio(`/exp0.mp3`);
    exp2 = new Audio(`/exp1.mp3`);
    exp3 = new Audio(`/exp2.mp3`);
}

const requestAnimFrame = (function () {
    if (isBrowser) {
        return (
            window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            function (callback) {
                window.setTimeout(callback, 1000 / 60);
            }
        );
    }
})();

let timerTick = 0;
let timerTotal = 80;
let limiterTotal = 5;
let limiterTick = 0;
let hue = 120;

const canvasWidth = 800;
const canvasHeight = 600;

interface Props {
    canvasDimension: Dimension;
    ws: Socket;
    refUserFireworks: React.MutableRefObject<any[]>;
    soundEnabled: boolean;
}

const Fireworks = ({
    canvasDimension,
    ws,
    refUserFireworks,
    soundEnabled,
}: Props) => {
    const refCanvas = React.useRef(null);
    const refFireworks = React.useRef([]);
    const refParticles = React.useRef([]);
    const refLastSent = React.useRef(0);
    const refCtx = React.useRef(null);

    const mousedown = false;

    const getPos = (e: any): Position => {
        const getMousePos = (offsetX: number, offsetY: number): Position => {
            //     if (canvasDimension.width !== 800 || canvasDimension.height !== 600) {
            //         return {
            //             x: offsetX / zoom * (800 / canvasDimension.width),
            //             y: offsetY / zoom * (600 / canvasDimension.height),
            //         };
            //     }
            const diffWidth = 800 / canvasDimension.width;
            const diffHeight = 600 / canvasDimension.height;

            return {
                x: (offsetX / 1) * diffWidth,
                y: (offsetY / 1) * diffHeight,
            };
        };

        var rect = refCanvas.current.getBoundingClientRect();

        if (e.touches !== undefined || e.changedTouches !== undefined) {
            var touch = e.touches[0] || e.changedTouches[0];

            const touchOffsetX = touch.clientX - rect.left;
            const touchOffsetY = touch.clientY - rect.top;
            // touch.offsetX = touch.clientX - rect.left
            // touch.offsetY = touch.clientY - rect.top
            return getMousePos(touchOffsetX, touchOffsetY);
        } else {
            // e.offsetX = e.clientX - rect.left
            // e.offsetY = e.clientY - rect.top

            const modifiedOffsetX = e.clientX - rect.left;
            const modifiedOffsetY = e.clientY - rect.top;

            return getMousePos(modifiedOffsetX, modifiedOffsetY);
        }
    };

    React.useEffect(() => {
        const onMouseDownEvent = (e) => {
            e.preventDefault();
            const pos = getPos(e);
            // console.log(pos);

            if (refLastSent.current + 350 < Date.now()) {
                refFireworks.current.push(
                    new Firework(
                        refCtx.current,
                        refParticles.current,
                        refFireworks.current,
                        true,
                        random(0, canvasWidth), // random(0, canvasDimension.width), //canvasDimension.width / 2,
                        canvasHeight, //canvasDimension.height,
                        pos.x,
                        pos.y,
                        soundEnabled
                    )
                );

                refLastSent.current = new Date().getTime();

                if (ws) {
                    ws.emit('rocket', { type: 'rocket', x: pos.x, y: pos.y });
                }
            }
        };
        if (refCanvas.current) {
            // window.addEventListener('mousemove', onMouseMoveEvent)

            if (refCanvas.current) {
                refCanvas.current.addEventListener(
                    'mousedown',
                    onMouseDownEvent,
                    {
                        passive: false,
                    }
                );
                refCanvas.current.addEventListener(
                    'touchstart',
                    onMouseDownEvent,
                    {
                        passive: false,
                    }
                );
                // ref.current.addEventListener('wheel', onWheel, { passive: false });
            }
        }

        return () => {
            // window.removeEventListener('mousemove', onMouseMoveEvent)

            if (refCanvas.current) {
                refCanvas.current.removeEventListener(
                    'mousedown',
                    onMouseDownEvent
                );
                refCanvas.current.removeEventListener(
                    'touchstart',
                    onMouseDownEvent
                );
                // ref.current.removeEventListener('wheel', onWheel);
            }
        };
    }, [refCanvas.current, canvasDimension, soundEnabled]);

    React.useEffect(() => {
        let hasExited = false;
        if (refCanvas.current) {
            // console.log(canvasDimension.width, canvasDimension.height);
            refCtx.current = refCanvas.current.getContext('2d');
            // console.log(refCtx.current)

            const loop = () => {
                // this function will run endlessly with requestAnimationFrame
                if (!hasExited) {
                    requestAnimFrame(loop);
                }

                while (refUserFireworks.current.length > 0) {
                    const pos = refUserFireworks.current.shift();
                    refFireworks.current.push(
                        new Firework(
                            refCtx.current,
                            refParticles.current,
                            refFireworks.current,
                            true,
                            random(0, canvasWidth), // random(0, canvasDimension.width), //canvasDimension.width / 2,
                            canvasHeight, //canvasDimension.height,
                            pos.x,
                            pos.y,
                            soundEnabled
                        )
                    );
                }
                // increase the hue to get different colored fireworks over time
                //hue += 0.5;

                // create random color
                hue = random(0, 360);

                // normally, clearRect() would be used to clear the canvas
                // we want to create a trailing effect though
                // setting the composite operation to destination-out will allow us to clear the canvas at a specific opacity, rather than wiping it entirely
                refCtx.current.globalCompositeOperation = 'destination-out';
                // decrease the alpha property to create more prominent trails
                refCtx.current.fillStyle = 'rgba(0, 0, 0, 0.5)';
                refCtx.current.fillRect(
                    0,
                    0,
                    canvasWidth, //canvasDimension.width,
                    canvasHeight //canvasDimension.height
                );
                // change the composite operation back to our main mode
                // lighter creates bright highlight points as the fireworks and particles overlap each other
                refCtx.current.globalCompositeOperation = 'lighter';

                // loop over each firework, draw it, update it
                var i = refFireworks.current.length;
                while (i--) {
                    refFireworks.current[i].draw();
                    refFireworks.current[i].update(i);
                }

                // loop over each particle, draw it, update it
                var i = refParticles.current.length;
                while (i--) {
                    refParticles.current[i].draw();
                    refParticles.current[i].update(i);
                }

                // launch fireworks automatically to random coordinates, when the mouse isn't down
                // if (timerTick >= timerTotal) {
                //     if (!mousedown) {
                //         // start the firework at the bottom middle of the screen, then set the random target coordinates, the random y coordinates will be set within the range of the top half of the screen
                //         refFireworks.current.push(
                //             new Firework(
                //                 refCtx.current,
                //                 refParticles.current,
                //                 refFireworks.current,
                //                 false,
                //                 random(10, canvasWidth - 10),
                //                 canvasHeight,
                //                 random(0, canvasWidth),
                //                 random(50, canvasHeight / 2),
                //                 soundEnabled
                //             )
                //         );
                //         timerTick = 0;
                //     }
                // } else {
                //     timerTick++;
                // }

                // limit the rate at which fireworks get launched when mouse is down
                if (limiterTick >= limiterTotal) {
                    // if (mousedown) {
                    //     // start the firework at the bottom middle of the screen, then set the current mouse coordinates as the target
                    //     refFireworks.current.push(new Firework(refCtx.current, refParticles.current, cw / 2, ch, mx, my));
                    //     limiterTick = 0;
                    // }
                } else {
                    limiterTick++;
                }
            };

            loop();
        }

        return () => {
            hasExited = true;
        };
    }, [refCanvas.current, canvasDimension, soundEnabled]);

    return (
        <canvas
            ref={refCanvas}
            // width={canvasDimension.width}
            // height={canvasDimension.height}
            width={800}
            height={600}
        />
    );
};

export default Fireworks;

function random(min, max) {
    return Math.random() * (max - min) + min;
}

// calculate the distance between two points
function calculateDistance(p1x, p1y, p2x, p2y) {
    var xDistance = p1x - p2x,
        yDistance = p1y - p2y;
    return Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2));
}

// create firework
function Firework(
    ctx,
    particles,
    fireworks,
    showPulse,
    sx,
    sy,
    tx,
    ty,
    soundEnabled
) {
    this.showPulse = showPulse;
    // console.log(ctx, particles, fireworks)
    this.fireworks = fireworks;
    this.ctx = ctx;
    this.particles = particles;
    // actual coordinates
    this.x = sx;
    this.y = sy;
    // starting coordinates
    this.sx = sx;
    this.sy = sy;
    // target coordinates
    this.tx = tx;
    this.ty = ty;
    // distance from starting point to target
    this.distanceToTarget = calculateDistance(sx, sy, tx, ty);
    this.distanceTraveled = 0;
    // track the past coordinates of each firework to create a trail effect, increase the coordinate count to create more prominent trails
    this.coordinates = [];
    this.coordinateCount = 3;
    // populate initial coordinate collection with the current coordinates
    while (this.coordinateCount--) {
        this.coordinates.push([this.x, this.y]);
    }
    this.angle = Math.atan2(ty - sy, tx - sx);
    this.speed = 1;
    this.acceleration = 1.02; //1.05;
    this.brightness = random(50, 70);
    // circle target indicator radius
    this.targetRadius = 1;
    this.soundEnabled = soundEnabled;
}

// update firework
Firework.prototype.update = function (index) {
    // remove last item in coordinates array
    this.coordinates.pop();
    // add current coordinates to the start of the array
    this.coordinates.unshift([this.x, this.y]);

    // cycle the circle target indicator radius
    if (this.targetRadius < 8) {
        this.targetRadius += 0.3;
    } else {
        this.targetRadius = 1;
    }

    // speed up the firework
    this.speed *= this.acceleration;

    // get the current velocities based on angle and speed
    var vx = Math.cos(this.angle) * this.speed,
        vy = Math.sin(this.angle) * this.speed;
    // how far will the firework have traveled with velocities applied?
    this.distanceTraveled = calculateDistance(
        this.sx,
        this.sy,
        this.x + vx,
        this.y + vy
    );

    // if the distance traveled, including velocities, is greater than the initial distance to the target, then the target has been reached
    if (this.distanceTraveled >= this.distanceToTarget) {
        lastSound++;

        if (lastSound > 2) {
            lastSound = 0;
        }
        if (this.soundEnabled) {
            try {
                if (lastSound === 0) {
                    // exp1.volume = Math.max(Math.random() * 0.5, .5);
                    exp1.volume = 0.4;
                    exp1.currentTime = 0;
                    exp1.play();
                } else if (lastSound === 1) {
                    // exp2.volume = Math.max(Math.random() * 0.5, .5);\
                    exp2.volume = 0.4;
                    exp2.currentTime = 0;
                    exp2.play();
                } else if (lastSound === 2) {
                    // exp3.volume = Math.max(Math.random() * 0.5, .5);
                    exp3.volume = 0.4;
                    exp3.currentTime = 0;
                    exp3.play();
                }
            } catch (err) {}
        }
        createParticles(this.ctx, this.particles, this.tx, this.ty);
        // remove the firework, use the index passed into the update function to determine which to remove
        this.fireworks.splice(index, 1);
    } else {
        // target not reached, keep traveling
        this.x += vx;
        this.y += vy;
    }
};

// draw firework
Firework.prototype.draw = function () {
    this.ctx.beginPath();
    // move to the last tracked coordinate in the set, then draw a line to the current x and y
    this.ctx.moveTo(
        this.coordinates[this.coordinates.length - 1][0],
        this.coordinates[this.coordinates.length - 1][1]
    );
    this.ctx.lineTo(this.x, this.y);
    this.ctx.strokeStyle = 'hsl(' + hue + ', 100%, ' + this.brightness + '%)';
    this.ctx.stroke();

    if (this.showPulse) {
        this.ctx.beginPath();
        // draw the target for this firework with a pulsing circle
        this.ctx.arc(this.tx, this.ty, this.targetRadius, 0, Math.PI * 2);
        this.ctx.stroke();
    }
};

// create particle
function Particle(ctx, particles, x, y) {
    this.particles = particles;
    this.ctx = ctx;
    this.x = x;
    this.y = y;
    // track the past coordinates of each particle to create a trail effect, increase the coordinate count to create more prominent trails
    this.coordinates = [];
    this.coordinateCount = 5;
    while (this.coordinateCount--) {
        this.coordinates.push([this.x, this.y]);
    }
    // set a random angle in all possible directions, in radians
    this.angle = random(0, Math.PI * 2);
    this.speed = random(1, 10);
    // friction will slow the particle down
    this.friction = 0.95;
    // gravity will be applied and pull the particle down
    this.gravity = 1;
    // set the hue to a random number +-50 of the overall hue variable
    this.hue = random(hue - 50, hue + 50);
    this.brightness = random(50, 80);
    this.alpha = 1;
    // set how fast the particle fades out
    this.decay = random(0.015, 0.03);
}

// update particle
Particle.prototype.update = function (index) {
    // remove last item in coordinates array
    this.coordinates.pop();
    // add current coordinates to the start of the array
    this.coordinates.unshift([this.x, this.y]);
    // slow down the particle
    this.speed *= this.friction;
    // apply velocity
    this.x += Math.cos(this.angle) * this.speed;
    this.y += Math.sin(this.angle) * this.speed + this.gravity;
    // fade out the particle
    this.alpha -= this.decay;

    // remove the particle once the alpha is low enough, based on the passed in index
    if (this.alpha <= this.decay) {
        this.particles.splice(index, 1);
    }
};

// draw particle
Particle.prototype.draw = function () {
    this.ctx.beginPath();
    // move to the last tracked coordinates in the set, then draw a line to the current x and y
    this.ctx.moveTo(
        this.coordinates[this.coordinates.length - 1][0],
        this.coordinates[this.coordinates.length - 1][1]
    );
    this.ctx.lineTo(this.x, this.y);
    this.ctx.strokeStyle =
        'hsla(' +
        this.hue +
        ', 100%, ' +
        this.brightness +
        '%, ' +
        this.alpha +
        ')';
    this.ctx.stroke();
};

// create particle group/explosion
function createParticles(ctx, particles, x, y) {
    // increase the particle count for a bigger explosion, beware of the canvas performance hit with the increased particles though
    var particleCount = 30;
    while (particleCount--) {
        particles.push(new Particle(ctx, particles, x, y));
    }
}

// mouse event bindings
// update the mouse coordinates on mousemove
// canvas.addEventListener('mousemove', function (e) {
//     mx = e.pageX - canvas.offsetLeft;
//     my = e.pageY - canvas.offsetTop;
// });

// // toggle mousedown state and prevent canvas from being selected
// canvas.addEventListener('mousedown', function (e) {
//     e.preventDefault();
//     mousedown = true;
// });

// canvas.addEventListener('mouseup', function (e) {
//     e.preventDefault();
//     mousedown = false;
// });

// once the window loads, we are ready for some fireworks!
// window.onload = loop;
