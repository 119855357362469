import classNames from 'classnames';
import React from 'react';
import { Socket } from 'socket.io-client';
import { useAppDispatch } from '../../../hooks';
import { setCurrentDrawing } from '../../../store/reducers/drawingSlice';
import * as styles from './index.module.scss';
import {
    Dimension,
    DrawQueueMessage,
    LayerProps,
    Position,
    SelectedTool,
} from './types';

const getPixel = (pixelData, x, y) => {
    if (x < 0 || y < 0 || x >= pixelData.width || y >= pixelData.height) {
        return -1; // impossible color
    } else {
        return pixelData.data[y * pixelData.width + x];
    }
};

interface MultiplePositions {
    posInt: Position;
    posFloat: Position;
    pos: Position;
}

interface Props {
    refDrawQueue: React.MutableRefObject<DrawQueueMessage[]>;
    zoom?: number;
    translate?: Position;
    activeLayer: LayerProps;
    thickness?: number;
    setThickness: React.Dispatch<React.SetStateAction<number>>;
    selectedTool: string;
    canvasDimension: Dimension;
    isDrawing: boolean;
    setDrawLayer: React.Dispatch<React.SetStateAction<LayerProps>>;
    setDrawEvents: React.Dispatch<React.SetStateAction<DrawQueueMessage[]>>;
    drawEvents: DrawQueueMessage[];
    ws: Socket;
    selectedColor: string;
    hasDrawEvents: boolean;
    setHasDrawEvents: React.Dispatch<React.SetStateAction<boolean>>;
    refUndos: React.MutableRefObject<ImageData[]>;
    setHasUndo: React.Dispatch<React.SetStateAction<boolean>>;
    drawer?: string;
    setHasDrawn?: React.Dispatch<React.SetStateAction<boolean>>;
}

const DrawLayer = ({
    refDrawQueue,
    zoom,
    translate,
    // setApplyDrawing,
    activeLayer,
    selectedTool,
    canvasDimension,
    isDrawing,
    setDrawLayer,
    setDrawEvents,
    drawEvents,
    ws,
    selectedColor,
    hasDrawEvents,
    setHasDrawEvents,
    refUndos,
    setHasUndo,
    drawer,
    thickness,
    setThickness,
    setHasDrawn,
}: Props) => {
    const dispatch = useAppDispatch();
    const ref = React.useRef<HTMLCanvasElement>(null);
    const refLastThickness = React.useRef<number>(thickness);
    const [ctx, setCtx] = React.useState<CanvasRenderingContext2D>(null);
    const [isMouseDown, setIsMouseDown] = React.useState(false);
    const [isDrawingEvents, setIsDrawingEvents] = React.useState(false);
    const [hasDrawerChanged, setHasDrawerChanged] = React.useState(false);

    const refIsDrawingEvents = React.useRef(false);

    const [smoothDrawEvents, setSmoothDrawEvents] = React.useState<
        DrawQueueMessage[]
    >([]);

    const prevDrawer = React.useRef<string | null>(null);

    const refDrawerSelectedTool = React.useRef<SelectedTool>({
        tool: 'pencil',
        thickness: 3,
        color: 'rgba(0, 0, 0, 255)',
    });
    const refMouseDownPos = React.useRef<Position>({ x: 0, y: 0 });
    const refDrawEventTimer = React.useRef(null);

    // const [canvasDimension, setCanvasDimension] = React.useState({ width: 0, height: 0 })

    const refLastPos = React.useRef<MultiplePositions>({
        pos: { x: 0, y: 0 },
        posInt: { x: 0, y: 0 },
        posFloat: { x: 0, y: 0 },
    });

    const refDrawOrder = React.useRef(0);

    const saveDrawingToStorage = () => {
        dispatch(setCurrentDrawing(activeLayer.canvas.toDataURL('image/png')));
    };

    React.useLayoutEffect(() => {
        prevDrawer.current = drawer;
        setSmoothDrawEvents([]);
        setHasDrawerChanged(true);
        setIsDrawingEvents(false);
        refIsDrawingEvents.current = false;
    }, [drawer]);

    React.useEffect(() => {
        if (ref.current) {
            const context = ref.current.getContext('2d', {
                willReadFrequently: true,
            });
            context.webkitImageSmoothingEnabled =
                context.imageSmoothingEnabled =
                context.mozImageSmoothingEnabled =
                context.oImageSmoothingEnabled =
                    false;
            setCtx(context);
            setDrawLayer({ canvas: ref.current, ctx: context });
            // const { width, height } = ref.current.getBoundingClientRect();
            // setCanvasDimension({
            //     width,
            //     height,
            // })
        }
    }, [ref.current]);

    React.useEffect(() => {
        if (!isDrawing) {
            setIsMouseDown(false);
            clearCanvas();
            refDrawOrder.current = 0;
        } else {
            refDrawOrder.current = 0;
        }
    }, [isDrawing]);

    const clearCanvas = () => {
        // const ctx = activeLayer.canvas.getContext('2d')
        if (activeLayer && activeLayer.ctx) {
            activeLayer.ctx.fillStyle = '#fff';
            activeLayer.ctx.fillRect(0, 0, 800, 600);
        }

        if (ctx) {
            ctx.clearRect(0, 0, 800, 600);
        }
    };

    const getPosInt = (offsetX: number, offsetY: number) => {
        var pos = getMousePos(offsetX, offsetY);
        pos.x = Math.round(pos.x) - Math.round(translate.x);
        pos.y = Math.round(pos.y) - Math.round(translate.y);

        return pos;
    };

    const getPosFloat = (offsetX: number, offsetY: number) => {
        var pos = getMousePos(offsetX, offsetY);
        pos.x = pos.x - translate.x;
        pos.y = pos.y - translate.y;

        return pos;
    };

    // get mouse pos relative to canvas (yours is fine, this is just different)
    const getMousePos = (offsetX: number, offsetY: number): Position => {
        //     if (canvasDimension.width !== 800 || canvasDimension.height !== 600) {
        //         return {
        //             x: offsetX / zoom * (800 / canvasDimension.width),
        //             y: offsetY / zoom * (600 / canvasDimension.height),
        //         };
        //     }
        const diffWidth = 800 / canvasDimension.width;
        const diffHeight = 600 / canvasDimension.height;

        return {
            x: (offsetX / zoom) * diffWidth,
            y: (offsetY / zoom) * diffHeight,
        };
    };

    const getPos = (e: any): MultiplePositions => {
        var rect = ref.current.getBoundingClientRect();

        if (e.touches !== undefined || e.changedTouches !== undefined) {
            var touch = e.touches[0] || e.changedTouches[0];

            const touchOffsetX = touch.clientX - rect.left;
            const touchOffsetY = touch.clientY - rect.top;
            // touch.offsetX = touch.clientX - rect.left
            // touch.offsetY = touch.clientY - rect.top
            return {
                posInt: getPosInt(touchOffsetX, touchOffsetY),
                posFloat: getPosFloat(touchOffsetX, touchOffsetY),
                pos: getMousePos(touchOffsetX, touchOffsetY),
            };
        } else {
            // e.offsetX = e.clientX - rect.left
            // e.offsetY = e.clientY - rect.top

            const modifiedOffsetX = e.clientX - rect.left;
            const modifiedOffsetY = e.clientY - rect.top;

            return {
                posInt: getPosInt(modifiedOffsetX, modifiedOffsetY),
                posFloat: getPosFloat(modifiedOffsetX, modifiedOffsetY),
                pos: getMousePos(modifiedOffsetX, modifiedOffsetY),
            };
        }
    };

    const floodFill = (drawCtx, x: number, y: number, fillColor) => {
        // read the pixels in the canvas
        const imageData = drawCtx.getImageData(
            0,
            0,
            ctx.canvas.width,
            ctx.canvas.height
        );

        // make a Uint32Array view on the pixels so we can manipulate pixels
        // one 32bit value at a time instead of as 4 bytes per pixel
        const pixelData = {
            width: imageData.width,
            height: imageData.height,
            data: new Uint32Array(imageData.data.buffer),
        };

        // get the color we're filling
        const targetColor = getPixel(pixelData, x, y);

        // check we are actually filling a different color
        if (targetColor !== fillColor) {
            const pixelsToCheck = [x, y];
            while (pixelsToCheck.length > 0) {
                const y = pixelsToCheck.pop();
                const x = pixelsToCheck.pop();

                const currentColor = getPixel(pixelData, x, y);
                if (currentColor === targetColor) {
                    pixelData.data[y * pixelData.width + x] = fillColor;
                    pixelsToCheck.push(x + 1, y);
                    pixelsToCheck.push(x - 1, y);
                    pixelsToCheck.push(x, y + 1);
                    pixelsToCheck.push(x, y - 1);
                }
            }

            // put the data back
            drawCtx.putImageData(imageData, 0, 0);
        }
    };

    const fillPixelatedCircle = (ctx, cx, cy, r) => {
        r |= 0; // floor radius
        // ctx.setTransform(1, 0, 0, 1, 0, 0); // ensure default transform
        var x = r,
            y = 0,
            dx = 1,
            dy = 1;
        var err = dx - (r << 1);
        var x0 = (cx - 1) | 0,
            y0 = cy | 0;
        var lx = x,
            ly = y;
        ctx.beginPath();
        while (x >= y) {
            ctx.rect(x0 - x, y0 + y, x * 2 + 2, 1);
            ctx.rect(x0 - x, y0 - y, x * 2 + 2, 1);
            if (x !== lx) {
                ctx.rect(x0 - ly, y0 - lx, ly * 2 + 2, 1);
                ctx.rect(x0 - ly, y0 + lx, ly * 2 + 2, 1);
            }
            lx = x;
            ly = y;
            y++;
            err += dy;
            dy += 2;
            if (err > 0) {
                x--;
                dx += 2;
                err += (-r << 1) + dx;
            }
        }
        if (x !== lx) {
            ctx.rect(x0 - ly, y0 - lx, ly * 2 + 1, 1);
            ctx.rect(x0 - ly, y0 + lx, ly * 2 + 1, 1);
        }
        ctx.fill();
    };

    const setPixel = (
        drawCtx: CanvasRenderingContext2D,
        drawerThickness: number,
        x: number,
        y: number
    ) => {
        if (selectedTool === 'pencil') {
            switch (drawerThickness) {
                case 1:
                    drawCtx.fillRect(x, y, 1, 1);
                    break;
                case 2:
                    drawCtx.fillRect(x - 1, y - 1, 2, 2);
                    break;
                case 3:
                    drawCtx.fillRect(x - 1, y, 3, 1);
                    drawCtx.fillRect(x, y - 1, 1, 1);
                    drawCtx.fillRect(x, y + 1, 1, 1);
                    break;
                case 4:
                    drawCtx.fillRect(x - 2, y - 1, 4, 2);
                    drawCtx.fillRect(x - 1, y - 2, 2, 1);
                    drawCtx.fillRect(x - 1, y + 1, 2, 1);
                    break;
                case 5:
                    drawCtx.fillRect(x - 2, y - 2, 3, 5);
                    drawCtx.fillRect(x - 3, y - 1, 5, 3);
                    // drawCtx.fillRect(x - 1, y + 1, 2, 1);
                    // fillPixelatedCircle(drawCtx, x, y, 2);
                    break;
                case 7:
                    drawCtx.fillRect(x - 4, y - 5, 5, 9);
                    drawCtx.fillRect(x - 6, y - 3, 9, 5);
                    // fillPixelatedCircle(drawCtx, x, y, 3);
                    break;
                case 9:
                    fillPixelatedCircle(drawCtx, x, y, 4);
                    break;
                case 11:
                    fillPixelatedCircle(drawCtx, x, y, 5);
                    break;
                case 13:
                    fillPixelatedCircle(drawCtx, x, y, 6);
                    break;
                default:
                    drawCtx.beginPath();
                    drawCtx.imageSmoothingEnabled = false;
                    drawCtx.imageSmoothingQuality = 'low';
                    drawCtx.arc(
                        x + 0.5,
                        y + 0.5,
                        drawerThickness / 2,
                        0,
                        2 * Math.PI
                    );
                    drawCtx.fill();
                    // drawCtx.stroke();
                    break;
            }
        } else {
            switch (drawerThickness) {
                case 1:
                    drawCtx.fillRect(x, y, 1, 1);
                    break;
                case 2:
                    drawCtx.fillRect(x - 1, y - 1, 2, 2);
                    break;
                case 3:
                    drawCtx.fillRect(x - 1, y, 3, 1);
                    drawCtx.fillRect(x, y - 1, 1, 1);
                    drawCtx.fillRect(x, y + 1, 1, 1);
                    break;
                case 4:
                    drawCtx.fillRect(x - 2, y - 1, 4, 2);
                    drawCtx.fillRect(x - 1, y - 2, 2, 1);
                    drawCtx.fillRect(x - 1, y + 1, 2, 1);
                    break;
                case 5:
                case 7:
                case 9:
                case 11:
                    drawCtx.fillRect(
                        x - Math.ceil(drawerThickness / 2),
                        y - Math.ceil(drawerThickness / 2),
                        drawerThickness,
                        drawerThickness
                    );

                    break;
                default:
                    drawCtx.beginPath();
                    // drawCtx.imageSmoothingEnabled = false;
                    // drawCtx.imageSmoothingQuality = 'low';
                    drawCtx.arc(
                        x + 0.5,
                        y + 0.5,
                        drawerThickness / 2,
                        0,
                        2 * Math.PI
                    );
                    drawCtx.fill();
                    // drawCtx.stroke();
                    break;
            }
        }
    };

    const plotLine = (drawCtx, drawerThickness: number, x0, y0, x1, y1) => {
        let dx = Math.abs(x1 - x0),
            sx = x0 < x1 ? 1 : -1;
        let dy = -Math.abs(y1 - y0),
            sy = y0 < y1 ? 1 : -1;
        let err = dx + dy,
            e2; /* error value e_xy */

        for (;;) {
            setPixel(drawCtx, drawerThickness, x0, y0);
            if (x0 === x1 && y0 === y1) break;
            e2 = 2 * err;
            if (e2 >= dy) {
                err += dy;
                x0 += sx;
            } /* x step */
            if (e2 <= dx) {
                err += dx;
                y0 += sy;
            } /* y step */
        }
    };

    const plotEllipse = (drawCtx, drawerThickness: number, xm, ym, a, b) => {
        var x = -a,
            y = 0; /* II. quadrant from bottom left to top right */
        var e2,
            dx = (1 + 2 * x) * b * b; /* error increment  */
        var dy = x * x,
            err = dx + dy; /* error of 1.step */

        do {
            setPixel(
                drawCtx,
                drawerThickness,
                xm - x,
                ym + y
            ); /*   I. Quadrant */
            setPixel(
                drawCtx,
                drawerThickness,
                xm + x,
                ym + y
            ); /*  II. Quadrant */
            setPixel(
                drawCtx,
                drawerThickness,
                xm + x,
                ym - y
            ); /* III. Quadrant */
            setPixel(
                drawCtx,
                drawerThickness,
                xm - x,
                ym - y
            ); /*  IV. Quadrant */
            e2 = 2 * err;
            if (e2 >= dx) {
                x++;
                err += dx += 2 * b * b;
            } /* x step */
            if (e2 <= dy) {
                y++;
                err += dy += 2 * a * a;
            } /* y step */
        } while (x <= 0);

        while (y++ < b) {
            /* too early stop for flat ellipses with a=1, */
            setPixel(
                drawCtx,
                drawerThickness,
                xm,
                ym + y
            ); /* -> finish tip of ellipse */
            setPixel(drawCtx, drawerThickness, xm, ym - y);
        }
    };

    const plotRect = (drawCtx, drawerThickness: number, x1, y1, x2, y2) => {
        // Top
        plotLine(drawCtx, drawerThickness, x1, y1, x2, y1);

        // Right
        plotLine(drawCtx, drawerThickness, x2, y2, x2, y1);

        // Bottom
        plotLine(drawCtx, drawerThickness, x1, y2, x2, y2);

        // Left
        plotLine(drawCtx, drawerThickness, x1, y1, x1, y2);
    };

    // const plotEllipseRect = (drawCtx, x0, y0, x1, y1) => {
    //     /* rectangular parameter enclosing the ellipse */
    //     var a = Math.abs(x1 - x0), b = Math.abs(y1 - y0), b1 = b & 1;        /* diameter */
    //     var dx = 4 * (1.0 - a) * b * b, dy = 4 * (b1 + 1) * a * a;              /* error increment */
    //     var err = dx + dy + b1 * a * a, e2;                             /* error of 1.step */

    //     if (x0 > x1) { x0 = x1; x1 += a; }        /* if called with swapped povars */
    //     if (y0 > y1) y0 = y1;                                  /* .. exchange them */
    //     y0 += (b + 1) >> 1; y1 = y0 - b1;                              /* starting pixel */
    //     a = 8 * a * a; b1 = 8 * b * b;

    //     do {
    //         setPixel(drawCtx, x1, y0);                                      /*   I. Quadrant */
    //         setPixel(drawCtx, x0, y0);                                      /*  II. Quadrant */
    //         setPixel(drawCtx, x0, y1);                                      /* III. Quadrant */
    //         setPixel(drawCtx, x1, y1);                                      /*  IV. Quadrant */
    //         e2 = 2 * err;
    //         if (e2 <= dy) { y0++; y1--; err += dy += a; }                 /* y step */
    //         if (e2 >= dx || 2 * err > dy) { x0++; x1--; err += dx += b1; }       /* x */
    //     } while (x0 <= x1);

    //     while (y0 - y1 <= b) {                /* too early stop of flat ellipses a=1 */
    //         setPixel(drawCtx, x0 - 1, y0);                         /* -> finish tip of ellipse */
    //         setPixel(drawCtx, x1 + 1, y0++);
    //         setPixel(drawCtx, x0 - 1, y1);
    //         setPixel(drawCtx, x1 + 1, y1--);
    //     }
    // }

    // const plotCircle = (drawCtx, xm, ym, r) => {
    //     let x = -r, y = 0, err = 2 - 2 * r;                /* bottom left to top right */
    //     do {
    //         setPixel(drawCtx, xm - x, ym + y);                            /*   I. Quadrant +x +y */
    //         setPixel(drawCtx, xm - y, ym - x);                            /*  II. Quadrant -x +y */
    //         setPixel(drawCtx, xm + x, ym - y);                            /* III. Quadrant -x -y */
    //         setPixel(drawCtx, xm + y, ym + x);                            /*  IV. Quadrant +x -y */
    //         r = err;
    //         if (r <= y) err += ++y * 2 + 1;                                   /* y step */
    //         if (r > x || err > y) err += ++x * 2 + 1;                         /* x step */
    //     } while (x < 0);
    // }

    const setLastPos = (pos) => {
        refLastPos.current.pos.x = pos.pos.x;
        refLastPos.current.pos.y = pos.pos.y;
        refLastPos.current.posInt.x = pos.posInt.x;
        refLastPos.current.posInt.y = pos.posInt.y;
        refLastPos.current.posFloat.x = pos.posFloat.x;
        refLastPos.current.posFloat.y = pos.posFloat.y;
    };

    const onMouseMove = (pos: MultiplePositions, isDownEvent: boolean) => {
        if (!isMouseDown && !isDownEvent) {
            return;
        }

        let sendEvent = true;

        if (!isDrawing) return;

        switch (selectedTool) {
            case 'eraser':
                ctx.fillStyle = 'rgba(255, 255, 255, 255)';
                // ctx.strokeStyle = 'rgba(255, 255, 255, 255)'
                plotLine(
                    ctx,
                    thickness,
                    refLastPos.current.posInt.x,
                    refLastPos.current.posInt.y,
                    pos.posInt.x,
                    pos.posInt.y
                );
                break;
            case 'pencil':
                ctx.fillStyle = selectedColor;
                plotLine(
                    ctx,
                    thickness,
                    refLastPos.current.posInt.x,
                    refLastPos.current.posInt.y,
                    pos.posInt.x,
                    pos.posInt.y
                );
                break;
            case 'fill':
                // BBGGRR öfug röð
                let hex8 = `${selectedColor[5]}${selectedColor[6]}${selectedColor[3]}${selectedColor[4]}${selectedColor[1]}${selectedColor[2]}`;
                floodFill(
                    activeLayer.ctx,
                    pos.posInt.x,
                    pos.posInt.y,
                    parseInt(`0xFF${hex8}`, 16)
                );

                saveDrawingToStorage();
                break;
            case 'line':
                sendEvent = false;

                ctx.fillStyle = selectedColor;
                // ctx.strokeStyle = selectedColor;
                ctx.clearRect(0, 0, 800, 600);
                plotLine(
                    ctx,
                    thickness,
                    refMouseDownPos.current.x,
                    refMouseDownPos.current.y,
                    Math.round(pos.posInt.x),
                    Math.round(pos.posInt.y)
                );
                break;
            case 'circle': {
                sendEvent = false;
                ctx.fillStyle = selectedColor;
                // ctx.strokeStyle = selectedColor;
                ctx.clearRect(0, 0, 800, 600);
                const distanceX =
                    Math.round(pos.posInt.x) - refMouseDownPos.current.x;
                const distanceY =
                    Math.round(pos.posInt.y) - refMouseDownPos.current.y;
                const distanceAbsX = Math.abs(distanceX);
                const distanceAbsY = Math.abs(distanceY);

                // plotCircle(ctx, refMouseDownPos.current.x, refMouseDownPos.current.y, Math.max(distanceX, distanceY) / 2);
                plotEllipse(
                    ctx,
                    thickness,
                    Math.round(refMouseDownPos.current.x + distanceX / 2),
                    Math.round(refMouseDownPos.current.y + distanceY / 2),
                    Math.round(distanceAbsX / 2),
                    Math.round(distanceAbsY / 2)
                );
                break;
            }
            case 'box':
                sendEvent = false;
                ctx.fillStyle = selectedColor;
                // ctx.strokeStyle = selectedColor;
                // ctx.lineCap = 'square';
                ctx.clearRect(0, 0, 800, 600);

                plotRect(
                    ctx,
                    thickness,
                    Math.round(refMouseDownPos.current.x),
                    Math.round(refMouseDownPos.current.y),
                    Math.round(pos.posInt.x),
                    Math.round(pos.posInt.y)
                );
                break;
            default:
                break;
        }

        if (sendEvent) {
            if (isDownEvent) {
                refDrawQueue.current.push({
                    ...pos.posInt,
                    tool: selectedTool,
                    thickness,
                    color: selectedColor,
                });
                refLastThickness.current = thickness;
                // setTempDrawEvents(tempDrawEvents => setTempDrawEvents([...tempDrawEvents, { ...pos.posInt, tool: selectedTool, thickness, color: selectedColor }]))
            } else {
                // TODO: Optimize later
                // if (pos.posInt.x === refLastPos.current.posInt.x) {
                //     setTempDrawEvents(tempDrawEvents => setTempDrawEvents([...tempDrawEvents, { y: pos.posInt.y }]))
                // } else if (pos.posInt.y === refLastPos.current.posInt.y) {
                //     setTempDrawEvents(tempDrawEvents => setTempDrawEvents([...tempDrawEvents, { x: pos.posInt.x }]))
                // } else {
                //     setTempDrawEvents(tempDrawEvents => setTempDrawEvents([...tempDrawEvents, { ...pos.posInt }]))
                // }
                if (pos.posInt.x === refLastPos.current.posInt.x) {
                }

                let drawEvent = {
                    ...pos.posInt,
                };

                if (thickness !== refLastThickness.current) {
                    drawEvent.thickness = thickness;
                    refLastThickness.current = thickness;
                }

                refDrawQueue.current.push(drawEvent);
                // setTempDrawEvents(tempDrawEvents => setTempDrawEvents([...tempDrawEvents, { ...pos.posInt }]))
            }

            setHasDrawEvents(true);
        }

        setLastPos(pos);
    };

    const onDrawerUndo = () => {
        if (refUndos.current.length === 0) {
            return;
        }
        activeLayer.ctx.clearRect(0, 0, 800, 600);
        activeLayer.ctx.putImageData(refUndos.current.pop(), 0, 0);
    };

    const drawEvent = (event: DrawQueueMessage) => {
        if (event.tool) {
            // Don't add undo as an undo!
            if (event.tool !== 'undo') {
                refUndos.current.push(
                    activeLayer.ctx.getImageData(0, 0, 800, 600)
                );
                // console.log('Adding undo')
                if (refUndos.current.length > 15) {
                    refUndos.current.splice(0, 1);
                }
            }
            // console.log('EVENT FOR TOOL', event)
            refDrawerSelectedTool.current.tool = event.tool;
            refDrawerSelectedTool.current.thickness = event.thickness;
            refDrawerSelectedTool.current.color = event.color;
            refLastPos.current.posInt.x = event.x;
            refLastPos.current.posInt.y = event.y;
        }

        if (event.thickness) {
            refDrawerSelectedTool.current.thickness = event.thickness;
        }

        switch (refDrawerSelectedTool.current.tool) {
            case 'eraser':
                activeLayer.ctx.fillStyle = 'rgba(255, 255, 255, 255)';
                plotLine(
                    activeLayer.ctx,
                    refDrawerSelectedTool.current.thickness,
                    refLastPos.current.posInt.x,
                    refLastPos.current.posInt.y,
                    event.x,
                    event.y
                );
                break;
            case 'pencil':
                activeLayer.ctx.fillStyle = refDrawerSelectedTool.current.color;
                plotLine(
                    activeLayer.ctx,
                    refDrawerSelectedTool.current.thickness,
                    refLastPos.current.posInt.x,
                    refLastPos.current.posInt.y,
                    event.x,
                    event.y
                );
                break;
            case 'fill':
                let hex8 = `${refDrawerSelectedTool.current.color[5]}${refDrawerSelectedTool.current.color[6]}${refDrawerSelectedTool.current.color[3]}${refDrawerSelectedTool.current.color[4]}${refDrawerSelectedTool.current.color[1]}${refDrawerSelectedTool.current.color[2]}`;
                floodFill(
                    activeLayer.ctx,
                    event.x,
                    event.y,
                    parseInt(`0xFF${hex8}`, 16)
                );
                break;
            case 'line':
                activeLayer.ctx.fillStyle = refDrawerSelectedTool.current.color;
                // ctx.clearRect(0, 0, 800, 600);
                plotLine(
                    activeLayer.ctx,
                    refDrawerSelectedTool.current.thickness,
                    event.start.x,
                    event.start.y,
                    Math.round(event.end.x),
                    Math.round(event.end.y)
                );
                break;
            case 'circle': {
                activeLayer.ctx.fillStyle = refDrawerSelectedTool.current.color;
                const distanceX = Math.round(event.end.x) - event.start.x;
                const distanceY = Math.round(event.end.y) - event.start.y;
                const distanceAbsX = Math.abs(distanceX);
                const distanceAbsY = Math.abs(distanceY);

                plotEllipse(
                    activeLayer.ctx,
                    refDrawerSelectedTool.current.thickness,
                    Math.round(event.start.x + distanceX / 2),
                    Math.round(event.start.y + distanceY / 2),
                    Math.round(distanceAbsX / 2),
                    Math.round(distanceAbsY / 2)
                );
                break;
            }
            case 'box':
                activeLayer.ctx.fillStyle = refDrawerSelectedTool.current.color;
                plotRect(
                    activeLayer.ctx,
                    refDrawerSelectedTool.current.thickness,
                    Math.round(event.start.x),
                    Math.round(event.start.y),
                    Math.round(event.end.x),
                    Math.round(event.end.y)
                );
                break;
            case 'clear':
                clearCanvas();
                break;
            case 'undo':
                onDrawerUndo();
                break;
            default:
                break;
        }

        if (event.x != null) {
            refLastPos.current.posInt.x = event.x;
        }
        if (event.y != null) {
            refLastPos.current.posInt.y = event.y;
        }
    };

    React.useEffect(() => {
        if (hasDrawerChanged) {
            setHasDrawerChanged(false);
        }
        if (
            drawEvents.length > 0 &&
            !isDrawingEvents &&
            !hasDrawerChanged &&
            !refIsDrawingEvents.current
        ) {
            if (drawEvents.length > 50) {
                // Lets draw all events immediately if we have too many draw events.
                for (let i = 0; i < drawEvents.length; i++) {
                    drawEvent(drawEvents[i]);
                }
            } else {
                // Otherwise lets draw smoothly!
                refIsDrawingEvents.current = true;
                setIsDrawingEvents(true);
                setSmoothDrawEvents((prev) => [...prev, ...drawEvents]);
            }

            setDrawEvents([]);
        }
    }, [
        drawEvents,
        setDrawEvents,
        setSmoothDrawEvents,
        isDrawingEvents,
        hasDrawerChanged,
    ]);

    React.useEffect(() => {
        if (
            drawEvents.length === 0 &&
            smoothDrawEvents.length === 0 &&
            activeLayer
        ) {
            saveDrawingToStorage();
        }
    }, [drawEvents, smoothDrawEvents, activeLayer]);

    React.useEffect(() => {
        if (smoothDrawEvents.length > 0 && !hasDrawerChanged) {
            refIsDrawingEvents.current = true;
            let i = 0;
            let interval = setInterval(
                () => {
                    if (i < smoothDrawEvents.length) {
                        drawEvent(smoothDrawEvents[i]);
                        i++;
                    } else {
                        clearInterval(interval);
                        interval = null;
                        setIsDrawingEvents(false);
                        refIsDrawingEvents.current = false;
                        setSmoothDrawEvents([]);
                    }
                },
                smoothDrawEvents.length > 50 ? 5 : 15
            );

            return () => {
                if (interval) {
                    clearInterval(interval);
                    interval = null;
                    setIsDrawingEvents(false);
                    refIsDrawingEvents.current = false;
                    setSmoothDrawEvents([]);
                }
            };
        }

        return;
    }, [smoothDrawEvents, setIsDrawingEvents, hasDrawerChanged]);

    // React.useEffect(() => {
    //     if (submitDrawEvents) {
    //         console.log('SEND', tempDrawEvents)
    //         setTempDrawEvents([])
    //         if (refWs.current) {
    //             refWs.current.send(JSON.stringify({ type: 'draw', list: tempDrawEvents, drawOrder: refDrawOrder.current++ }))
    //         }
    //         setSubmitDrawEvents(false)
    //     }
    // }, [tempDrawEvents, setTempDrawEvents, submitDrawEvents])

    // React.useEffect(() => {
    //     if (tempDrawEvents.length > 0) {
    //         if (refDrawEventTimer.current == null) {
    //             refDrawEventTimer.current = setTimeout(() => {
    //                 setSubmitDrawEvents(true)

    //                 console.log('SEND', tempDrawEvents)
    //                 if (refWs.current) {
    //                     refWs.current.send(JSON.stringify({ type: 'draw', list: tempDrawEvents, drawOrder: refDrawOrder.current++ }))
    //                 }
    //                 setTempDrawEvents([])

    //                 refDrawEventTimer.current = null
    //                 // console.log('Send: ', tempDrawEvents)
    //                 // setTempDrawEvents([])
    //             }, 300)
    //         }
    //     }
    // }, [tempDrawEvents, setTempDrawEvents, setSubmitDrawEvents])

    React.useEffect(() => {
        if (hasDrawEvents) {
            if (refDrawEventTimer.current == null) {
                refDrawEventTimer.current = setTimeout(() => {
                    // console.log('SEND', refDrawQueue.current)
                    if (ws) {
                        ws.emit('draw', {
                            type: 'draw',
                            list: refDrawQueue.current,
                        });
                    }
                    refDrawQueue.current = [];
                    setHasDrawEvents(false);
                    // setTempDrawEvents([])

                    refDrawEventTimer.current = null;
                    // setTempDrawEvents([])
                }, 300);
            }
        }
    }, [hasDrawEvents]);

    React.useEffect(() => {
        if (isDrawing) {
            if (ref.current) {
                ref.current.addEventListener('wheel', onWheel, {
                    passive: false,
                });
            }

            return () => {
                if (ref.current) {
                    ref.current.removeEventListener('wheel', onWheel);
                }
            };
        }

        return;
    }, [isDrawing, thickness]);

    React.useEffect(() => {
        if (isMouseDown) {
            const onMouseMoveEvent = (e) => {
                e.preventDefault();
                // const rect = ref.current.getBoundingClientRect()
                // e.offsetX = e.clientX - rect.left
                // e.offsetY = e.clientY - rect.top\
                onMouseMove(getPos(e), false); //, e.clientX - rect.left, e.clientY - rect.top));
            };

            const onMouseUpEvent = (e) => {
                e.preventDefault();
                if (setHasDrawn) {
                    setHasDrawn(true);
                }
                setIsMouseDown(false);
                activeLayer.ctx.drawImage(ref.current, 0, 0);
                ctx.clearRect(0, 0, 800, 600);

                // Only specific tools require mouse up events
                if (
                    selectedTool === 'box' ||
                    selectedTool === 'circle' ||
                    selectedTool === 'line'
                ) {
                    refDrawQueue.current.push({
                        start: { ...refMouseDownPos.current },
                        end: { ...refLastPos.current.posInt },
                        tool: selectedTool,
                        thickness,
                        color: selectedColor,
                    });
                    setHasDrawEvents(true);
                }

                saveDrawingToStorage();
            };

            // window.addEventListener('wheel', onWheel, { passive: false });
            window.addEventListener('mousemove', onMouseMoveEvent, {
                passive: false,
            });
            window.addEventListener('touchmove', onMouseMoveEvent);
            window.addEventListener('touchend', onMouseUpEvent);
            window.addEventListener('mouseup', onMouseUpEvent);

            return () => {
                // window.removeEventListener('wheel', onWheel);
                window.removeEventListener('mousemove', onMouseMoveEvent);
                window.removeEventListener('touchmove', onMouseMoveEvent);
                window.removeEventListener('touchend', onMouseUpEvent);
                window.removeEventListener('mouseup', onMouseUpEvent);
            };
        }
        return () => {};
    }, [
        isMouseDown,
        activeLayer,
        canvasDimension,
        ctx,
        selectedTool,
        isDrawing,
        thickness,
    ]);

    const addToUndo = () => {
        setHasUndo(true);
        refUndos.current.push(activeLayer.ctx.getImageData(0, 0, 800, 600));
        if (refUndos.current.length > 15) {
            refUndos.current.splice(0, 1);
        }
    };

    const onWheel = (e) => {
        const maxThickness = 23;
        e.preventDefault();
        if (e.deltaY < 0) {
            if (thickness < maxThickness) {
                setThickness(Math.min(thickness + 2, maxThickness));
            }
        } else {
            if (thickness > 0) {
                setThickness(Math.max(thickness - 2, 1));
            }
        }

        // value={thickness === 1 ? 0 : thickness}
        // step={2}
        // min={0}
        // max={18}
    };

    React.useEffect(() => {
        if (!isDrawing) {
            return;
        }
        const onMouseDownEvent = (e) => {
            e.preventDefault();
            if (!isMouseDown) {
                // Add current image to the undo list
                addToUndo();
                const mouseDownPos = getPos(e);

                refMouseDownPos.current = {
                    x: mouseDownPos.posInt.x,
                    y: mouseDownPos.posInt.y,
                };
                setLastPos(mouseDownPos);
                onMouseMove(mouseDownPos, true);
                if (selectedTool !== 'fill') {
                    setIsMouseDown(true);
                }
            }
        };

        // window.addEventListener('mousemove', onMouseMoveEvent)

        if (ref.current) {
            ref.current.addEventListener('mousedown', onMouseDownEvent, {
                passive: false,
            });
            ref.current.addEventListener('touchstart', onMouseDownEvent, {
                passive: false,
            });
            // ref.current.addEventListener('wheel', onWheel, { passive: false });
        }

        return () => {
            // window.removeEventListener('mousemove', onMouseMoveEvent)

            if (ref.current) {
                ref.current.removeEventListener('mousedown', onMouseDownEvent);
                ref.current.removeEventListener('touchstart', onMouseDownEvent);
                // ref.current.removeEventListener('wheel', onWheel);
            }
        };
    }, [
        ref.current,
        isMouseDown,
        canvasDimension,
        isDrawing,
        selectedTool,
        selectedColor,
        setHasUndo,
        thickness,
    ]);

    return (
        <canvas
            id='drawCanvas'
            width='800'
            height='600'
            ref={ref}
            className={classNames(
                styles.drawCanvas,
                isDrawing && styles.isDrawing
            )}
        />
    );
};

export default DrawLayer;
