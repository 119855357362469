import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Helmet } from 'react-helmet';
import DrawLayer from '../DrawLayer';
import Layer from '../Layer';
import classNames from 'classnames';
import { Link } from 'gatsby';
import GameClock from '../GameClock';
import { isProfanity } from '../../../helpers/profanityHelper';
import {
    CalculatedSizes,
    Dimension,
    DrawQueueMessage,
    GuessType,
    LayerProps,
    Position,
    ResultType,
    RoomInfo,
    ScoreType,
    UserType,
} from '../types';
import Tools from '../Tools';
import { useAdsenseLoader, useAppDispatch } from '../../../hooks';
import Adsense from '../../Ad';
import Announcement from './Announcement';
import User from './User';
import WinnersScreen from './Winners';
import LastWordScreen from './LastWordScreen';
import Modal from '../../Modal';
import { AuthUserState, setSessionId } from '../../../store/reducers/userSlice';
import { Socket } from 'socket.io-client';
import Votes from './Votes';
import Button from '../../Button';
import DrawingsModal from '../../../features/DrawingsModal';
import * as styles from './index.module.scss';
import {
    addDrawing,
    addNewDrawing,
    setCurrentDrawing,
} from '../../../store/reducers/drawingSlice';

const isBrowser = typeof window !== 'undefined';
let audioFoundWord: HTMLAudioElement;
let audioEnterRoom: HTMLAudioElement;
let audioWin: HTMLAudioElement;
let audioSomeoneFoundWord: HTMLAudioElement;
let audioYourTurn: HTMLAudioElement;
let audioTick: HTMLAudioElement;
let audioNextDrawer: HTMLAudioElement;
let audioHint: HTMLAudioElement;
let audioWhistle: HTMLAudioElement;
let audioSessionResult: HTMLAudioElement;
let audioNotification: HTMLAudioElement;
let audioReveal: HTMLAudioElement;
let audioBlow: HTMLAudioElement;
// let audioDrumRing: HTMLAudioElement;
let audioFail: HTMLAudioElement;
let audioSmallTick: HTMLAudioElement;

if (isBrowser) {
    audioFoundWord = new Audio(`/foundword.mp3`);
    audioEnterRoom = new Audio(`/enterroom.wav`);
    audioWhistle = new Audio(`/whistle.wav`);
    audioWin = new Audio(`/win.wav`);
    audioSomeoneFoundWord = new Audio(`/someonefoundword.mp3`);
    audioYourTurn = new Audio(`/yourturn.wav`);
    audioTick = new Audio(`/tick5.wav`);
    audioNextDrawer = new Audio(`/nextdrawer.wav`);
    audioHint = new Audio(`/hint.wav`);
    audioSessionResult = new Audio(`/sessionresult.wav`);
    audioNotification = new Audio(`/notification.wav`);
    audioReveal = new Audio(`/reveal.wav`);
    audioBlow = new Audio(`/blow.wav`);
    // audioDrumRing = new Audio(`/audioDrumRing.wav`);
    audioFail = new Audio(`/fail.wav`);
    audioSmallTick = new Audio(`/tick3.wav`);
}

interface Props extends RouteComponentProps {
    roomId?: string;
    user: AuthUserState;
    state: any;
    dispatch: React.Dispatch<any>;
    ws: Socket;
    roomInfo: RoomInfo;
    isObserver: boolean;
}

const EveryoneDraws = ({
    roomId,
    user,
    state,
    dispatch,
    ws,
    roomInfo,
    isObserver,
}: Props) => {
    useAdsenseLoader();
    const appDispatch = useAppDispatch();
    const refChat = React.useRef<HTMLDivElement>(null);
    const refChatInput = React.useRef<HTMLInputElement>(null);
    const refDrawQueue = React.useRef<DrawQueueMessage[]>([]);
    const refUndos = React.useRef<ImageData[]>([]);
    const refNumFoundWord = React.useRef(0);
    const [soundEnabled, setSoundEnabled] = React.useState(true);
    const [roomName, setRoomName] = React.useState('');
    const [wordList, setWordList] = React.useState('english');
    const [gameFinished, setGameFinished] = React.useState<UserType[] | null>(
        null
    );
    const [showDrawingsModal, setShowDrawingsModal] = React.useState(false);
    const [drawTime, setDrawTime] = React.useState(70);
    const [rounds, setRounds] = React.useState(10);
    const [lastSentMsg, setLastSentMsg] = React.useState(0);
    const [guesses, setGuesses] = React.useState([]);
    const [isGuessMode, setIsGuessMode] = React.useState(false);
    const [hasFoundWord, setHasFoundWord] = React.useState(false);
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [isPickingWord, setIsPickingWord] = React.useState(false);
    const [numPickableWords, setNumPickableWords] = React.useState(0);
    const [lastWord, setLastWord] = React.useState<string | null>(null);
    const [hasGuessed, setHasGuessed] = React.useState<boolean>(false);
    const [hasVoted, setHasVoted] = React.useState(false);
    const [score, setScore] = React.useState<ScoreType[] | null>(null);
    const [result, setResult] = React.useState<ResultType | null>(null);

    const refUserFireworks = React.useRef([]);
    const refLastPing = React.useRef(0);

    const [translate, setTranslate] = React.useState<Position>({
        x: 0,
        y: 0,
    });
    const [canvasDimension, setCanvasDimension] = React.useState<Dimension>({
        width: 1,
        height: 1,
    });
    const [calculatedSizes, setCalculatedSizes] =
        React.useState<CalculatedSizes>({
            canvasWidth: 800,
            canvasHeight: 600,
            canvasMobileWidth: 200,
            canvasMobileHeight: 100,
            gameWidth: 100,
            gameWidthMobile: 100,
            isMobile: false,
        });
    const [zoom, setZoom] = React.useState(1);
    const [layers, setLayers] = React.useState([
        {
            id: '0',
        },
    ]);

    const [selectedColor, setSelectedColor] = React.useState('#000000');
    const [hasSomeoneFoundWord, setHasSomeoneFoundWord] = React.useState(false);
    const [wordFound, setWordFound] = React.useState(false);
    const [isDrawing, setIsDrawing] = React.useState(!isObserver);
    const [selectedTool, setSelectedTool] = React.useState('pencil');
    const [thickness, setThickness] = React.useState(5);
    const [activeLayer, setActiveLayer] = React.useState<LayerProps>(null);
    const [drawLayer, setDrawLayer] = React.useState<LayerProps>(null);
    const [selectedWord, setSelectedWord] = React.useState('');
    const [showWord, setShowWord] = React.useState(false);
    const [drawer, setDrawer] = React.useState<string | null>(null);
    const [reportDrawer, setReportDrawer] = React.useState(null);
    const [canReport, setCanReport] = React.useState(false);
    const [isMouseDown, setIsMouseDown] = React.useState(false);
    const [users, setUsers] = React.useState<UserType[]>([
        // {
        //     name: `test${Math.floor(Math.random() * 100)}`,
        //     score: Math.floor(Math.random() * 120),
        //     isDrawing: false,
        //     hasFoundWord: Math.floor(Math.random() * 2) === 1,
        //     avatar: getRandomAvatar(),
        // },
    ]);
    const [message, setMessage] = React.useState('');
    const [messages, setMessages] = React.useState([]);
    const [shouldScroll, setShouldScroll] = React.useState(true);
    const [hasUndo, setHasUndo] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [roundStart, setRoundStart] = React.useState(0);
    const [roundExp, setRoundExp] = React.useState(0);
    const [currentRound, setCurrentRound] = React.useState(0);
    const [drawEvents, setDrawEvents] = React.useState<DrawQueueMessage[]>([]);
    const [hasDrawEvents, setHasDrawEvents] = React.useState(false);
    const [gamePhase, setGamePhase] = React.useState<
        'waiting' | 'drawing' | 'guessing' | 'voting' | 'result'
    >('waiting');

    const [myGuess, setMyGuess] = React.useState('');
    const [votedGuess, setVotedGuess] = React.useState('');
    const [hasDrawn, setHasDrawn] = React.useState(false);

    React.useEffect(() => {
        audioEnterRoom.preload = 'auto';
        audioFoundWord.preload = 'auto';
        audioSomeoneFoundWord.preload = 'auto';
        audioWin.preload = 'auto';
        audioTick.preload = 'auto';
        audioYourTurn.preload = 'auto';
        audioReveal.preload = 'auto';
        audioBlow.preload = 'auto';
        // audioDrumRing.preload = 'auto';
        audioFail.preload = 'auto';
        audioSmallTick.preload = 'auto';
        audioReveal.volume = 0.5;
        audioTick.volume = 0.5;
        audioBlow.volume = 0.5;
        // audioDrumRing.volume = 0.5;
        audioFail.volume = 0.4;
        audioSmallTick.volume = 0.5;

        return () => {
            try {
                audioTick.pause();
                audioTick.currentTime = 0;
            } catch (err) {
                console.log(err);
            }
        };
    }, []);

    React.useEffect(() => {
        if (isMouseDown) {
            const onMouseUpEvent = () => {
                setIsMouseDown(false);
            };
            window.addEventListener('touchend', onMouseUpEvent, {
                passive: false,
            });
            window.addEventListener('mouseup', onMouseUpEvent, {
                passive: false,
            });

            return () => {
                window.removeEventListener('touchend', onMouseUpEvent);
                window.removeEventListener('mouseup', onMouseUpEvent);
            };
        } else {
            const onMouseDownEvent = () => {
                setIsMouseDown(true);
            };
            window.addEventListener('mousedown', onMouseDownEvent, {
                passive: false,
            });
            window.addEventListener('touchstart', onMouseDownEvent, {
                passive: false,
            });

            return () => {
                window.removeEventListener('mousedown', onMouseDownEvent);
                window.removeEventListener('touchstart', onMouseDownEvent);
            };
        }
    }, [isMouseDown]);

    React.useEffect(() => {
        if (!soundEnabled) {
            try {
                audioTick.pause();
                audioTick.currentTime = 0;
            } catch (err) {
                console.log(err);
            }
        }
    }, [soundEnabled]);

    React.useEffect(() => {
        if (!isDrawing) {
            if (refChatInput.current) {
                refChatInput.current.focus();
            }

            setHasUndo(false);
            refUndos.current = [];
        }
    }, [isDrawing]);

    React.useEffect(() => {
        if (shouldScroll && refChat && refChat.current) {
            refChat.current.scrollTop = refChat.current.scrollHeight;
        }
    }, [shouldScroll, messages, calculatedSizes]);

    React.useEffect(() => {
        if (activeLayer && roomId.length > 0) {
            clearCanvas();
            addNewDrawingToStorage();
        }

        return () => {
            if (activeLayer) {
                addNewDrawingToStorage();
            }
        };
    }, [roomId, activeLayer]);

    const addDrawingToStorage = () => {
        appDispatch(addDrawing(activeLayer.canvas.toDataURL('image/png')));
    };

    const saveDrawingToStorage = () => {
        appDispatch(
            setCurrentDrawing(activeLayer.canvas.toDataURL('image/png'))
        );
    };

    const addNewDrawingToStorage = () => {
        appDispatch(addNewDrawing());
    };

    const addMessage = (msg) => {
        setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            if (updatedMessages.length > 100) {
                updatedMessages.splice(0, 1);
            }
            updatedMessages.push(msg);
            return updatedMessages; //[...prevMessages, msg];
        });
        if (refChat.current) {
            setShouldScroll(
                Math.ceil(
                    refChat.current.scrollHeight -
                        refChat.current.scrollTop -
                        50
                ) <= refChat.current.clientHeight
            );
            if (
                Math.ceil(
                    refChat.current.scrollHeight -
                        refChat.current.scrollTop -
                        50
                ) <= refChat.current.clientHeight
            ) {
                refChat.current.scrollTop = refChat.current.scrollHeight;
            }
        } else {
            setShouldScroll(true);
        }
    };

    React.useEffect(() => {
        // Process events
        if (state.events.length === 0) {
            return;
        }
        let updatedUsers = [...users];
        let hasUsersChanged = false;
        for (let i = 0; i < state.events.length; i++) {
            const event = state.events[i];
            switch (event.type) {
                case 'admin':
                    addMessage({
                        type: 'announcement',
                        msg: (
                            <>
                                <strong>
                                    😇 You are now admin. Use the force wisely.
                                </strong>
                            </>
                        ),
                    });
                    setIsAdmin(true);
                    break;
                case 'error':
                    setErrorMessage(event.error);
                    break;
                case 'debug':
                    addMessage({
                        type: 'announcement',
                        msg: (
                            <>
                                <strong>DEBUG: {event.msg}</strong>
                            </>
                        ),
                    });
                    break;
                case 'errors':
                    break;
                case 'ping':
                    refLastPing.current = Date.now();
                    break;
                case 'state':
                    appDispatch(setSessionId(event.sessionId));
                    if (event.word) {
                        setSelectedWord(event.word);
                    }
                    setCurrentRound(event.round);
                    setRounds(event.rounds);
                    setDrawTime(event.drawTime);
                    setGamePhase(event.gamePhase);
                    setHasVoted(event.hasVoted);
                    setHasGuessed(event.hasGuessed);

                    if (event.drawer) {
                        setDrawer(event.drawer);
                    }

                    if (event.isDrawing) {
                        addMessage({
                            type: 'announcement',
                            msg: (
                                <>
                                    ✏️ You are drawing the word{' '}
                                    <span>{event.word}</span>.
                                </>
                            ),
                        });

                        setIsDrawing(true);
                        setSelectedTool('pencil');
                        setSelectedColor('#000000');
                        setThickness(5);
                    }

                    if (event.guesses) {
                        setGuesses(event.guesses);
                    }

                    if (
                        window.document &&
                        window.document.documentElement &&
                        window.document.documentElement.scrollTop > 0
                    ) {
                        window.document.documentElement.scrollTop = 0;
                    }

                    if (event.round > 0) {
                        addMessage({
                            type: 'announcement',
                            msg: (
                                <>
                                    Game is in progress. Round {event.round} of{' '}
                                    {rounds}.
                                </>
                            ),
                        });
                    }

                    if (event.roundExp) {
                        setRoundExp(event.roundExp);
                    }
                    if (event.timeNow) {
                        setRoundStart(event.timeNow);
                    } else if (event.roundStart) {
                        setRoundStart(event.roundStart);
                    }
                    if (event.drawer) {
                        setDrawer(event.drawer);
                    }
                    setRoomName(event.name);
                    setWordList(event.wordList);
                    setIsDrawing(
                        (event.round === 0 || event.isDrawing) && !isObserver
                    );

                    break;
                case 'users':
                    updatedUsers = [...event.users];

                    for (let i = 0; i < updatedUsers.length; i++) {
                        if (updatedUsers[i].hasFoundWord) {
                            refNumFoundWord.current++;
                        }
                    }

                    if (refNumFoundWord.current > 0) {
                        setHasSomeoneFoundWord(true);
                    }

                    if (event.users.length < 2) {
                        addMessage({
                            type: 'announcement',
                            msg: (
                                <>
                                    🗨️ You are alone in this room, the game
                                    starts when {event.minUsers} players are in
                                    the room. Invite your friends to play!
                                </>
                            ),
                        });
                    } else if (event.users.length < event.minUsers) {
                        addMessage({
                            type: 'announcement',
                            msg: (
                                <>
                                    🗨️ The game starts when {event.minUsers}{' '}
                                    players are in the room.
                                </>
                            ),
                        });
                    }

                    break;
                case 'joined':
                    updatedUsers.push(event.user);
                    hasUsersChanged = true;
                    addMessage({
                        type: 'announcement',
                        msg: (
                            <>
                                👋 <strong>{event.user.name}</strong> joined.
                            </>
                        ),
                    });
                    if (soundEnabled) {
                        audioEnterRoom.currentTime = 0;
                        audioEnterRoom.volume = 0.5;
                        audioEnterRoom.play();
                    }
                    break;
                case 'quit':
                    updatedUsers = updatedUsers.filter(
                        (u) => u.name !== event.name
                    );
                    hasUsersChanged = true;
                    addMessage({
                        type: 'announcement',
                        msg: (
                            <>
                                <strong>{event.name}</strong> left.
                            </>
                        ),
                    });
                    break;
                case 'kick':
                    if (event.name !== user.name) {
                        addMessage({
                            type: 'announcement',
                            msg: (
                                <>
                                    👀 <strong>{event.name}</strong> was kicked
                                    out
                                    {event.reason.length > 0 ? (
                                        <>: {event.reason}</>
                                    ) : (
                                        ''
                                    )}
                                    .
                                </>
                            ),
                        });
                    }
                    break;
                case 'rocket':
                    if (gameFinished !== null) {
                        try {
                            refUserFireworks.current.push({
                                x: event.x,
                                y: event.y,
                            });
                        } catch (err) {}
                    }
                    break;
                case 'pong':
                    break;
                case 'msg':
                    let hasUserFoundWord = false;
                    for (let i = 0; i < updatedUsers.length; i++) {
                        if (
                            updatedUsers[i].name === event.name &&
                            updatedUsers[i].hasFoundWord
                        ) {
                            hasUserFoundWord = true;
                        }
                    }
                    addMessage({
                        type: 'chat',
                        name: hasUserFoundWord ? (
                            <span style={{ color: 'rgb(65 179 58)' }}>
                                ✔️ {event.name}
                            </span>
                        ) : (
                            <span>{event.name}</span>
                        ),
                        msg: event.msg,
                    });
                    break;
                case 'game_starting':
                    clearCanvas();
                    setSelectedWord('');
                    setHasFoundWord(false);
                    setCurrentRound(0);

                    addMessage({
                        type: 'announcement',
                        msg: (
                            <>
                                <strong>
                                    📢 New game is starting in {event.time}{' '}
                                    seconds!
                                </strong>
                            </>
                        ),
                    });
                    setIsDrawing(false);

                    for (let i = 0; i < updatedUsers.length; i++) {
                        updatedUsers[i] = {
                            ...updatedUsers[i],
                            isDrawing: false,
                            score: 0, // reset score
                        };
                    }

                    hasUsersChanged = true;
                    break;
                case 'word_found_by_everyone':
                    if (users.length > 2) {
                        addMessage({
                            type: 'announcement',
                            msg: (
                                <>
                                    👏{' '}
                                    <strong>
                                        Everyone found the word, good job!
                                    </strong>
                                </>
                            ),
                        });
                    }
                    setRoundExp(0);
                    setRoundStart(0);
                    break;
                case 'word_close':
                    addMessage({
                        type: 'announcement',
                        msg: (
                            <>
                                The word <strong>{event.word}</strong> is close,
                                keep trying!
                            </>
                        ),
                    });
                    break;
                case 'game_finished':
                    if (soundEnabled) {
                        try {
                            audioTick.pause();
                            audioTick.currentTime = 0;
                            audioWin.currentTime = 0;
                            audioWin.volume = 0.5;
                            audioWin.play();
                        } catch (err) {
                            console.log(err);
                        }
                    }

                    let tempUsers = [];

                    for (let i = 0; i < users.length; i++) {
                        if (i < 3) {
                            tempUsers.push(users[i]);
                        }
                    }

                    setGameFinished([...tempUsers]);

                    if (users.length > 2) {
                        addMessage({
                            type: 'announcement',
                            msg: (
                                <>
                                    ✨{' '}
                                    <strong>
                                        Game finished. The winners are
                                    </strong>
                                    <div>
                                        🏆 <span>1. {users[0].name}</span>{' '}
                                    </div>
                                    <div>
                                        🥈 2. <>{users[1].name}</>{' '}
                                    </div>
                                    <div>
                                        🥉 3. <>{users[2].name}</>{' '}
                                    </div>
                                </>
                            ),
                        });
                    } else if (users.length > 1) {
                        addMessage({
                            type: 'announcement',
                            msg: (
                                <>
                                    ✨{' '}
                                    <strong>
                                        Game finished. The winners are
                                    </strong>
                                    <div>
                                        🏆 <span>1. {users[0].name}</span>{' '}
                                    </div>
                                    <div>
                                        🥈 2. <>{users[1].name}</>{' '}
                                    </div>
                                </>
                            ),
                        });
                    } else {
                        addMessage({
                            type: 'announcement',
                            msg: (
                                <>
                                    <strong>✨ Game finished!</strong>
                                </>
                            ),
                        });
                    }
                    setIsDrawing(false);
                    setWordFound(false);
                    setHasSomeoneFoundWord(false);
                    setShowWord(false);
                    setIsGuessMode(false);
                    setDrawEvents([]);
                    clearCanvas();
                    break;
                case 'game_ending':
                    addMessage({
                        type: 'announcement',
                        msg: (
                            <>
                                <strong>
                                    🗨️ Game will end in 5 seconds unless someone
                                    enters the room.
                                </strong>
                            </>
                        ),
                    });
                    break;
                case 'game_ended':
                    if (soundEnabled) {
                        try {
                            audioTick.pause();
                            audioTick.currentTime = 0;
                        } catch (err) {
                            console.log(err);
                        }
                    }

                    for (let i = 0; i < updatedUsers.length; i++) {
                        updatedUsers[i] = {
                            ...updatedUsers[i],
                            score: 0,
                        };
                    }

                    hasUsersChanged = true;

                    addMessage({
                        type: 'announcement',
                        msg: (
                            <>
                                <strong>
                                    🗨️ Game ended since there are too few
                                    players to continue.
                                </strong>
                            </>
                        ),
                    });
                    setGamePhase('waiting');
                    setWordFound(false);
                    setRoundExp(0);
                    setRoundStart(0);
                    setCurrentRound(0);
                    setIsDrawing(true && !isObserver);
                    setGuesses([]);
                    setHasSomeoneFoundWord(false);
                    setShowWord(false);
                    clearCanvas();
                    setSelectedWord('');
                    setDrawEvents([]);
                    setIsGuessMode(false);
                    setHasFoundWord(false);
                    setIsPickingWord(false);
                    setLastWord(null);
                    break;
                case 'drawer_inactive':
                    addMessage({
                        type: 'announcement',
                        msg: (
                            <>
                                💤 <strong>{event.name}</strong> was inactive.
                                Skipping round.
                            </>
                        ),
                    });
                    break;
                case 'round_end':
                    if (soundEnabled) {
                        try {
                            audioTick.pause();
                            audioTick.currentTime = 0;
                        } catch (err) {
                            console.log(err);
                        }
                    }
                    // if (event.lastWord && event.lastWord.length > 0) {
                    //     setLastWord(event.lastWord);
                    //     if (event.numFoundWord === 0) {
                    //         addMessage({
                    //             type: 'announcement',
                    //             msg: (
                    //                 <>
                    //                     🗨️ No one guessed the word. The word was{' '}
                    //                     <span>{event.lastWord}</span>.
                    //                 </>
                    //             ),
                    //         });
                    //     } else {
                    //         addMessage({
                    //             type: 'announcement',
                    //             msg: (
                    //                 <>
                    //                     🗨️ The word was{' '}
                    //                     <span>{event.lastWord}</span>.
                    //                 </>
                    //             ),
                    //         });
                    //     }
                    // }
                    // setSelectedWord('');
                    setRoundExp(0);
                    setRoundStart(0);
                    // setShowWord(false);
                    clearCanvas();
                    setGamePhase('waiting');
                    // setIsGuessMode(false);
                    // setIsDrawing(false);
                    break;
                case 'drawing_phase':
                    if (isObserver) {
                        setGamePhase('drawing');
                    }
                    setIsGuessMode(false);
                    setMessage('');
                    clearCanvas();
                    setGameFinished(null);
                    if (event.roundStart) {
                        setRoundStart(event.roundStart);
                    }
                    if (event.roundExp) {
                        setRoundExp(event.roundExp);
                    }
                    if (event.round === 1) {
                        for (let i = 0; i < updatedUsers.length; i++) {
                            // updatedUsers[i].score = 0;
                            updatedUsers[i] = {
                                ...updatedUsers[i],
                                score: 0,
                                isDrawing: true,
                                hasFoundWord: false,
                            };
                        }
                    } else {
                        for (let i = 0; i < updatedUsers.length; i++) {
                            updatedUsers[i] = {
                                ...updatedUsers[i],
                                isDrawing: true,
                                hasFoundWord: false,
                            };
                        }
                    }
                    hasUsersChanged = true;
                    if (event.round) {
                        setCurrentRound(event.round);
                    }
                    addMessage({
                        type: 'announcement',
                        msg: (
                            <>✏️ Everyone should draw their word or sentence.</>
                        ),
                    });

                    if (soundEnabled) {
                        audioYourTurn.volume = 0.6;
                        audioYourTurn.play();
                    }

                    break;
                case 'drawer_set':
                    setHasDrawn(false);
                    setHasFoundWord(false);
                    setDrawer(event.name);
                    setGamePhase('drawing');

                    setDrawEvents([]);
                    // setLastWord(null);

                    setWordFound(false);

                    if (soundEnabled) {
                        try {
                            audioTick.pause();
                            audioTick.currentTime = 0;
                        } catch (err) {
                            console.log(err);
                        }
                    }
                    refNumFoundWord.current = 0;
                    refUndos.current = [];

                    addMessage({
                        type: 'announcement',
                        msg: (
                            <>
                                ✏️ You are drawing the word{' '}
                                <span>{event.word}</span>.
                            </>
                        ),
                    });

                    setIsDrawing(true);
                    setSelectedTool('pencil');
                    setSelectedColor('#000000');
                    setThickness(5);
                    if (event.word) {
                        setSelectedWord(event.word);
                    }

                    if (
                        window.document &&
                        window.document.documentElement &&
                        window.document.documentElement.scrollTop > 0
                    ) {
                        window.document.documentElement.scrollTop = 0;
                    }
                    break;
                case 'guessing_phase':
                    setIsDrawing(false);
                    setShowWord(false);
                    clearCanvas();
                    setSelectedWord('');
                    setDrawEvents([]);
                    setGamePhase('guessing');
                    break;
                case 'guessing_session':
                    setHasVoted(false);
                    clearCanvas();
                    setDrawEvents([]);
                    setGamePhase('guessing');
                    setDrawer(event.name);
                    setGuesses([]);
                    if (soundEnabled) {
                        audioNotification.volume = 0.5;
                        audioNotification.play();
                    }
                    if (event.roundExp) {
                        setRoundExp(event.roundExp);
                    }
                    if (event.timeNow) {
                        setRoundStart(event.timeNow);
                    } else if (event.roundStart) {
                        setRoundStart(event.roundStart);
                    }

                    for (let i = 0; i < updatedUsers.length; i++) {
                        updatedUsers[i] = {
                            ...updatedUsers[i],
                            isDrawing: updatedUsers[i].name === event.name,
                            hasFoundWord: false,
                        };
                    }

                    hasUsersChanged = true;

                    if (event.name === user.name) {
                        addMessage({
                            type: 'announcement',
                            msg: (
                                <>🤔 Users are guessing what your drawing is</>
                            ),
                        });
                        setIsGuessMode(false);
                    } else {
                        setIsGuessMode(true);
                        setHasGuessed(false);
                        addMessage({
                            type: 'announcement',
                            msg: <>🤔 Guess what this is</>,
                        });
                    }
                    break;
                case 'submit_drawing':
                    if (event.name === user.name) {
                        addDrawingToStorage();
                        addMessage({
                            type: 'announcement',
                            msg: <>🗨️ You have submitted your drawing.</>,
                        });
                    } else {
                        addMessage({
                            type: 'announcement',
                            msg: <>🗨️ {event.name} has submitted a drawing.</>,
                        });

                        if (soundEnabled) {
                            audioHint.volume = 0.5;
                            audioHint.play();
                        }
                    }

                    for (let i = 0; i < updatedUsers.length; i++) {
                        if (updatedUsers[i].name === event.name) {
                            updatedUsers[i] = {
                                ...updatedUsers[i],
                                hasFoundWord: true,
                            };
                            break;
                        }
                    }
                    hasUsersChanged = true;
                    break;
                case 'guessed':
                    if (event.name === user.name) {
                        // setVotedGuess(event.guess);
                        setMyGuess(event.guess);

                        if (event.isCorrect) {
                            if (soundEnabled) {
                                audioFoundWord.volume = 0.5;
                                audioFoundWord.play();
                            }
                            setHasVoted(true);
                            setVotedGuess(event.guess);
                            addMessage({
                                type: 'announcement',
                                msg: (
                                    <>
                                        🗨️ You answered {event.guess}, which was
                                        the correct sentence! Good job!
                                    </>
                                ),
                            });
                        } else {
                            addMessage({
                                type: 'announcement',
                                msg: (
                                    <>
                                        🗨️ You answered{' '}
                                        <strong>{event.guess}</strong>.
                                    </>
                                ),
                            });
                            if (soundEnabled) {
                                audioHint.volume = 0.5;
                                audioHint.play();
                            }
                        }
                    } else {
                        addMessage({
                            type: 'announcement',
                            msg: <>🗨️ {event.name} has made an answer.</>,
                        });

                        if (soundEnabled) {
                            audioHint.volume = 0.5;
                            audioHint.play();
                        }
                    }

                    for (let i = 0; i < updatedUsers.length; i++) {
                        if (updatedUsers[i].name === event.name) {
                            updatedUsers[i] = {
                                ...updatedUsers[i],
                                hasFoundWord: true,
                            };
                            break;
                        }
                    }
                    hasUsersChanged = true;

                    break;
                case 'voting_session':
                    addDrawingToStorage();
                    setIsGuessMode(false);
                    setGuesses(event.guesses);
                    setGamePhase('voting');
                    if (event.roundExp) {
                        setRoundExp(event.roundExp);
                    }
                    if (event.timeNow) {
                        setRoundStart(event.timeNow);
                    } else if (event.roundStart) {
                        setRoundStart(event.roundStart);
                    }
                    addMessage({
                        type: 'announcement',
                        msg: <>🗨️ Guess the answer!</>,
                    });
                    if (soundEnabled) {
                        audioNextDrawer.volume = 0.5;
                        audioNextDrawer.play();
                    }
                    for (let i = 0; i < updatedUsers.length; i++) {
                        updatedUsers[i] = {
                            ...updatedUsers[i],
                            hasFoundWord: false,
                        };
                    }
                    hasUsersChanged = true;

                    break;
                case 'voted':
                    if (event.name === user.name) {
                        setVotedGuess(event.guess);

                        if (event.isCorrect) {
                            if (soundEnabled) {
                                audioFoundWord.volume = 0.5;
                                audioFoundWord.play();
                            }
                            addMessage({
                                type: 'announcement',
                                msg: (
                                    <>
                                        🗨️ You guessed {event.guess}, which was
                                        the sentence!
                                    </>
                                ),
                            });
                        } else {
                            addMessage({
                                type: 'announcement',
                                msg: <>🗨️ You guessed {event.guess}.</>,
                            });
                            if (soundEnabled) {
                                audioHint.volume = 0.5;
                                audioHint.play();
                            }
                        }
                    } else {
                        addMessage({
                            type: 'announcement',
                            msg: <>🗨️ {event.name} has guessed an answer.</>,
                        });

                        if (soundEnabled) {
                            audioHint.volume = 0.5;
                            audioHint.play();
                        }
                    }
                    for (let i = 0; i < updatedUsers.length; i++) {
                        if (updatedUsers[i].name === event.name) {
                            updatedUsers[i] = {
                                ...updatedUsers[i],
                                hasFoundWord: true,
                            };
                            break;
                        }
                    }
                    break;
                case 'session_result':
                    setHasVoted(false);
                    setGamePhase('result');
                    setGuesses([]);
                    setScore(event.score);

                    if (soundEnabled) {
                        audioSessionResult.volume = 0.5;
                        audioSessionResult.play();
                    }

                    for (let i = 0; i < updatedUsers.length; i++) {
                        if (updatedUsers[i].name === event.name) {
                            updatedUsers[i] = {
                                ...updatedUsers[i],
                                hasFoundWord: false,
                            };
                            break;
                        }
                    }

                    for (let i = 0; i < event.score.length; i++) {
                        for (let x = 0; x < updatedUsers.length; x++) {
                            if (updatedUsers[x].name === event.score[i].name) {
                                updatedUsers[x] = {
                                    ...updatedUsers[x],
                                    score: event.score[i].score,
                                };
                            }
                        }
                    }
                    hasUsersChanged = true;

                    event.result.guesses.sort((a: GuessType, b: GuessType) => {
                        return a.voters.length + a.guessers.length >
                            b.voters.length + b.guessers.length
                            ? -1
                            : 1;
                    });

                    const correctGuess = event.result.guesses.find(
                        (g) => g.isCorrect === true
                    );

                    if (event.result.guesses.length > 4) {
                        event.result.guesses = event.result.guesses.slice(0, 4);
                    }

                    let hasCorrectSentence = false;

                    for (let i = 0; i < event.result.guesses.length; i++) {
                        if (
                            event.result.guesses[i].sentence ===
                            event.result.sentence
                        ) {
                            hasCorrectSentence = true;
                            break;
                        }
                    }

                    if (!hasCorrectSentence) {
                        event.result.guesses.push(correctGuess);
                    }

                    setResult(event.result);
                    if (event.roundExp) {
                        setRoundExp(event.roundExp);
                    }
                    if (event.timeNow) {
                        setRoundStart(event.timeNow);
                    } else if (event.roundStart) {
                        setRoundStart(event.roundStart);
                    }
                    addMessage({
                        type: 'announcement',
                        msg: (
                            <>
                                🗨️ Here are the results! The sentence was{' '}
                                {event.word}
                            </>
                        ),
                    });
                    break;
                case 'draw':
                    setDrawEvents((drawEvents) => [
                        ...drawEvents,
                        ...event.list,
                    ]);
                    break;
                case 'report':
                    if (soundEnabled) {
                        try {
                            audioWhistle.volume = 0.5;
                            audioWhistle.play();
                        } catch (err) {}
                    }
                    addMessage({
                        type: 'announcement',
                        msg: (
                            <>
                                ⚠️{' '}
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    <strong>{event.reporter}</strong> reported{' '}
                                    <strong>{event.reporting}</strong> (
                                    {event.numReports}/{event.numReportsNeeded}
                                    ).
                                </span>
                            </>
                        ),
                    });
                    break;
                case 'notice':
                    addMessage({
                        type: 'announcement',
                        msg: (
                            <>
                                <span
                                    style={{
                                        fontSize: '1.1rem',
                                    }}
                                >
                                    📢{' '}
                                    <strong
                                        style={{
                                            color: 'red',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {' '}
                                        {event.msg}
                                    </strong>
                                </span>
                            </>
                        ),
                    });
                    break;
                default:
                    console.log('Unknown event:', event);
                    break;
            }
        }

        if (updatedUsers) {
            updatedUsers.sort((a, b) => b.score - a.score);
            setUsers(updatedUsers);
        }

        dispatch({
            type: 'processed',
            numEventsProcessed: state.events.length,
        });
    }, [
        state.events,
        users,
        setUsers,
        setMessages,
        setShouldScroll,
        setIsDrawing,
        shouldScroll,
        setShouldScroll,
        setWordFound,
        setDrawEvents,
        // hasEnteredRoom,
    ]);

    React.useEffect(() => {
        const isMobileEl = document.getElementById('isMobile');
        const onResizeEvent = () => {
            const isMobile =
                isMobileEl != null && isMobileEl.offsetParent !== null;
            const leftBar = 262;
            const rightBar = 262;
            const padding = 50;

            const sum = leftBar + rightBar + padding;

            let width = Math.max(
                document.documentElement.clientWidth - sum,
                320
            );
            let height;

            if (isMobile) {
                height = Math.max(window.innerHeight - 170, 240);
            } else {
                height = Math.max(window.innerHeight - 200, 240);
            }

            let mobileWidth = document.documentElement.clientWidth - 10;
            let mobileHeight = window.innerHeight;

            const aspectRatioWidth = 4;
            const aspectRatioHeight = 3;

            let canvasWidth = width;
            let canvasHeight = height;

            let canvasMobileWidth = mobileWidth;
            let canvasMobileHeight = mobileHeight;

            if (width / aspectRatioWidth > height / aspectRatioHeight) {
                canvasWidth = Math.floor(
                    (height / aspectRatioHeight) * aspectRatioWidth
                );
            } else {
                canvasHeight = Math.floor(
                    (width / aspectRatioWidth) * aspectRatioHeight
                );
            }

            if (
                mobileWidth / aspectRatioWidth >
                mobileHeight / aspectRatioHeight
            ) {
                canvasMobileWidth = Math.floor(
                    (mobileHeight / aspectRatioHeight) * aspectRatioWidth
                );
            } else {
                canvasMobileHeight = Math.floor(
                    (mobileWidth / aspectRatioWidth) * aspectRatioHeight
                );
            }

            if (canvasWidth > 800) {
                canvasWidth = 800;
            }
            if (canvasHeight > 600) {
                canvasHeight = 600;
            }

            // console.log(document.documentElement.clientWidth);

            setCalculatedSizes({
                canvasWidth, //: Math.min(640, canvasWidth),
                canvasHeight, //: Math.min(480, canvasHeight),
                canvasMobileWidth: canvasMobileWidth,
                canvasMobileHeight: canvasMobileHeight,
                gameWidth: canvasWidth + leftBar + rightBar,
                gameWidthMobile: canvasMobileWidth,
                // screenWidth: Math.ceil(document.documentElement.clientWidth),
                isMobile,
            });

            if (activeLayer != null) {
                const { width, height } =
                    activeLayer.canvas.getBoundingClientRect();
                setCanvasDimension({
                    width,
                    height,
                });
            }
        };

        if (activeLayer != null) {
            setTimeout(() => {
                onResizeEvent();
            }, 10);
        }

        window.addEventListener('resize', onResizeEvent);

        return () => {
            window.removeEventListener('resize', onResizeEvent);
        };
    }, [activeLayer]);

    const clearCanvas = () => {
        // const ctx = activeLayer.canvas.getContext('2d')
        activeLayer.ctx.fillStyle = '#fff';
        activeLayer.ctx.fillRect(0, 0, 800, 600);

        // drawLayer.ctx.fillStyle = '#fff'
        drawLayer.ctx.clearRect(0, 0, 800, 600);
    };

    const onEvent = (type: string): void => {
        switch (type) {
            case 'clear':
                if (isDrawing) {
                    // Add undo
                    setHasUndo(true);
                    refUndos.current.push(
                        activeLayer.ctx.getImageData(0, 0, 800, 600)
                    );
                    if (refUndos.current.length > 15) {
                        refUndos.current.splice(0, 1);
                    }
                    clearCanvas();
                    refDrawQueue.current.push({ tool: 'clear' });
                    setHasDrawEvents(true);
                    saveDrawingToStorage();
                }
                break;
            case 'undo':
                if (isDrawing) {
                    onUndo();
                    refDrawQueue.current.push({ tool: 'undo' });
                    setHasDrawEvents(true);
                    saveDrawingToStorage();
                }
                break;
            default:
                console.log('Type not found: ', type);
                break;
        }
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13 && message.length > 0) {
            const timeNow = new Date().getTime();
            const isSpamming = timeNow - lastSentMsg < 200;

            if (isProfanity(message)) {
                setMessage('');
                return;
            }

            if (message[0] === '/') {
                ws.emit('cmd', {
                    type: 'cmd',
                    msg: message.replace('/', ''),
                });
            } else if (!isObserver) {
                if (message.length > 1 && !isSpamming) {
                    ws.emit('msg', { type: 'msg', msg: message });

                    setHasGuessed(true);

                    setLastSentMsg(timeNow);

                    dispatch({
                        type: 'message',
                        event: {
                            type: 'msg',
                            name: hasFoundWord ? (
                                <span style={{ color: 'rgb(65 179 58)' }}>
                                    ✔️ {user.name}
                                </span>
                            ) : (
                                <span>{user.name}</span>
                            ),
                            msg: message,
                        },
                    });
                }
            }

            setMessage('');
        }
    };

    // React.useEffect(() => {
    //     const tempResult: ResultType = {
    //         guesses: [
    //             {
    //                 sentence: 'airplane',
    //                 guessers: ['Sara Bee'],
    //                 voters: ['test1', 'Some very long name', 'test3', 'Travis', 'John'],
    //                 isCorrect: true,
    //             },
    //             {
    //                 sentence: 'ufo',
    //                 guessers: ['Tester'],
    //                 voters: ['Peter', 'John'],
    //                 isCorrect: false,
    //             },
    //             {
    //                 sentence: 'duck',
    //                 guessers: [],
    //                 voters: [],
    //                 isCorrect: false,
    //             },
    //             // {
    //             //     sentence: 'quack quack',
    //             //     guessers: [],
    //             //     voters: [],
    //             //     isCorrect: false,
    //             // },
    //             // {
    //             //     sentence: 'weirdo',
    //             //     guessers: [],
    //             //     voters: [],
    //             //     isCorrect: false,
    //             // },
    //             {
    //                 sentence: 'bird',
    //                 guessers: ['Gaga'],
    //                 voters: [],
    //                 isCorrect: false,
    //             },
    //         ],
    //         sentence: 'bird',
    //         hasCorrectSentence: false,
    //     };

    //     const correctGuess = tempResult.guesses.find(
    //         (g) => g.isCorrect === true
    //     );

    //     tempResult.guesses.sort((a: GuessType, b: GuessType) => {
    //         return a.voters.length + a.guessers.length >
    //             b.voters.length + b.guessers.length
    //             ? -1
    //             : 1;
    //     });
    //     tempResult.guesses = tempResult.guesses.slice(0, 4);

    //     let hasCorrectSentence = false;

    //     for (let i = 0; i < tempResult.guesses.length; i++) {
    //         if (tempResult.guesses[i].sentence === tempResult.sentence) {
    //             hasCorrectSentence = true;
    //             break;
    //         }
    //     }

    //     if (!hasCorrectSentence) {
    //         tempResult.guesses.push(correctGuess);
    //     }

    //     if (soundEnabled) {
    //         audioSessionResult.volume = 0.5;
    //         audioSessionResult.play();
    //     }

    //     setResult(tempResult);
    //     setGamePhase('result');
    //     setCurrentRound(1);
    //     setRoundStart(1);
    // }, [users]);

    const onUndo = () => {
        if (refUndos.current.length === 0) {
            return;
        }
        activeLayer.ctx.clearRect(0, 0, 800, 600);
        activeLayer.ctx.putImageData(refUndos.current.pop(), 0, 0);
        drawLayer.ctx.clearRect(0, 0, 800, 600);

        if (refUndos.current.length === 0) {
            setHasUndo(false);
        }
    };

    const onEraseText = () => {
        setMessage('');
        if (refChatInput.current) {
            refChatInput.current.focus();
        }
    };

    const getDrawerUser = () => {
        const drawUsers = users.filter((u) => u.name === drawer);

        if (drawUsers.length > 0) {
            return drawUsers[0];
        }

        return null;
    };

    const onReportDrawer = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        if (reportDrawer) {
            setCanReport(false);
            ws.emit('report', { type: 'report', drawer: reportDrawer });
        }

        setReportDrawer(null);
    };

    const onReportClick = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        if (drawer != null) {
            setReportDrawer(drawer);
        }
    };

    const getPlaceholderText = () => {
        if (isObserver) {
            return 'Enter a command (chat is disabled)';
        }

        if (hasGuessed) {
            return 'You have guessed.';
        }

        return 'What is this?';
    };

    // React.useEffect(() => {
    //     setGuesses([
    //         'random',
    //         'airplane',
    //         'some long word',
    //         'another very long word',
    //         'lol',
    //         'wat',
    //         'wadda fuck!',
    //         'cool story bro',
    //         'roflmao',
    //         'hmm what is this',
    //         'ookie dokie',
    //         'yes sir',
    //         'last word?',
    //         'yawn',
    //         'super mario bros',
    //         'weeehoo',
    //         'skiiing',
    //         'tablet',
    //         'snowboarding',
    //     ]);
    //     setDrawer('a1')
    // }, []);

    const onSubmitDrawing = () => {
        if (isDrawing && ws) {
            ws.emit('submit_drawing', { type: 'submit_drawing' });
            setIsDrawing(false);

            if (soundEnabled) {
                audioHint.volume = 0.5;
                audioHint.play();
            }
        }
    };

    return (
        <>
            <Helmet>
                <title>{roomName || roomInfo.name} - Skissan</title>
            </Helmet>
            <div className={styles.isMobile} id='isMobile' />
            <div
                className={styles.room}
                style={{
                    width: !calculatedSizes.isMobile
                        ? calculatedSizes.gameWidth
                        : calculatedSizes.gameWidthMobile,
                }}
            >
                <h2>
                    <img src='/skissan.svg' />
                </h2>
                <div className={styles.game}>
                    <div
                        className={styles.leftMenu}
                        style={{
                            height: !calculatedSizes.isMobile
                                ? calculatedSizes.canvasHeight + 62
                                : 'auto',
                        }}
                    >
                        <div className={styles.leftMenuActions}>
                            <Button
                                size='medium'
                                color='default'
                                to='/games'
                                isLink
                            >
                                Exit
                            </Button>
                            <div>
                                <Button
                                    title='Drawings'
                                    color='transparent'
                                    size='small'
                                    onClick={() => setShowDrawingsModal(true)}
                                >
                                    <svg
                                        aria-hidden='true'
                                        focusable='false'
                                        data-prefix='fal'
                                        data-icon='images'
                                        role='img'
                                        xmlns='http://www.w3.org/2000/svg'
                                        viewBox='0 0 576 512'
                                    >
                                        <path
                                            fill='currentColor'
                                            d='M528 32H112c-26.51 0-48 21.49-48 48v16H48c-26.51 0-48 21.49-48 48v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48v-16h16c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm-48 400c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V144c0-8.822 7.178-16 16-16h16v240c0 26.51 21.49 48 48 48h368v16zm64-64c0 8.822-7.178 16-16 16H112c-8.822 0-16-7.178-16-16V80c0-8.822 7.178-16 16-16h416c8.822 0 16 7.178 16 16v288zM176 200c30.928 0 56-25.072 56-56s-25.072-56-56-56-56 25.072-56 56 25.072 56 56 56zm0-80c13.234 0 24 10.766 24 24s-10.766 24-24 24-24-10.766-24-24 10.766-24 24-24zm240.971 23.029c-9.373-9.373-24.568-9.373-33.941 0L288 238.059l-31.029-31.03c-9.373-9.373-24.569-9.373-33.941 0l-88 88A24.002 24.002 0 0 0 128 312v28c0 6.627 5.373 12 12 12h360c6.627 0 12-5.373 12-12v-92c0-6.365-2.529-12.47-7.029-16.971l-88-88zM480 320H160v-4.686l80-80 48 48 112-112 80 80V320z'
                                        ></path>
                                    </svg>
                                </Button>
                                <Button
                                    size='small'
                                    color='transparent'
                                    title='Toggle sound'
                                    className={styles.btnSound}
                                    onClick={() =>
                                        setSoundEnabled(!soundEnabled)
                                    }
                                >
                                    <img
                                        src='/volume.svg'
                                        alt='mute'
                                        style={{
                                            display: soundEnabled
                                                ? 'block'
                                                : 'none',
                                        }}
                                    />
                                    <img
                                        src='/mute.svg'
                                        alt='mute'
                                        style={{
                                            display: soundEnabled
                                                ? 'none'
                                                : 'block',
                                        }}
                                    />
                                </Button>
                            </div>
                        </div>
                        <div className={styles.userlist}>
                            {users.map((u) => (
                                <User
                                    key={u.name}
                                    isMe={u.name === user.name}
                                    user={u}
                                />
                            ))}
                        </div>
                    </div>
                    <div
                        className={classNames(
                            styles.viewport,
                            isDrawing && styles.isDrawing
                        )}
                        style={{
                            width: !calculatedSizes.isMobile
                                ? calculatedSizes.canvasWidth
                                : calculatedSizes.canvasMobileWidth,
                            height: !calculatedSizes.isMobile
                                ? calculatedSizes.canvasHeight
                                : calculatedSizes.canvasMobileHeight,
                        }}
                        onContextMenu={(e) => e.preventDefault()}
                    >
                        <div className={styles.nav}>
                            <div>
                                <h4>{roomName || 'English'}</h4>
                                {currentRound > 0 ? (
                                    <span>
                                        Round <strong>{currentRound}</strong> /{' '}
                                        {rounds}
                                    </span>
                                ) : (
                                    <span>
                                        {/* <i>Game has not started</i> */}
                                    </span>
                                )}
                            </div>
                            <div></div>

                            <div>
                                <GameClock
                                    drawTime={drawTime}
                                    roundExp={roundExp} //{(new Date().getTime() / 1000 | 0) + 70}
                                    roundStart={roundStart} //{(new Date().getTime() / 1000 | 0)}
                                    hasSomeoneFoundWord={hasSomeoneFoundWord}
                                />
                            </div>
                        </div>
                        {isGuessMode && canReport && (
                            <button
                                onClick={onReportClick}
                                className={styles.btnReportDrawer}
                                title='Report drawing'
                            >
                                <svg
                                    aria-hidden='true'
                                    focusable='false'
                                    data-prefix='far'
                                    data-icon='exclamation-circle'
                                    role='img'
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 512 512'
                                >
                                    <path
                                        fill='currentColor'
                                        d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm42-104c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42zm-81.37-211.401l6.8 136c.319 6.387 5.591 11.401 11.985 11.401h41.17c6.394 0 11.666-5.014 11.985-11.401l6.8-136c.343-6.854-5.122-12.599-11.985-12.599h-54.77c-6.863 0-12.328 5.745-11.985 12.599z'
                                    ></path>
                                </svg>
                            </button>
                        )}
                        <WinnersScreen
                            soundEnabled={soundEnabled}
                            gameFinished={gameFinished}
                            setGameFinished={setGameFinished}
                            canvasDimension={canvasDimension}
                            ws={ws}
                            refUserFireworks={refUserFireworks}
                        />
                        <DrawLayer
                            setHasDrawn={setHasDrawn}
                            refDrawQueue={refDrawQueue}
                            drawEvents={drawEvents}
                            setDrawEvents={setDrawEvents}
                            setDrawLayer={setDrawLayer}
                            zoom={zoom}
                            translate={translate}
                            activeLayer={activeLayer}
                            selectedTool={selectedTool}
                            canvasDimension={canvasDimension}
                            isDrawing={isDrawing && !isPickingWord}
                            ws={ws}
                            selectedColor={selectedColor}
                            hasDrawEvents={hasDrawEvents}
                            setHasDrawEvents={setHasDrawEvents}
                            refUndos={refUndos}
                            setHasUndo={setHasUndo}
                            drawer={drawer}
                            setThickness={setThickness}
                            thickness={thickness}
                        />
                        {layers.map((layer) => (
                            <Layer
                                zoom={zoom}
                                translate={translate}
                                key={layer.id}
                                setActiveLayer={setActiveLayer}
                            />
                        ))}

                        <Announcement
                            drawerName={drawer}
                            round={currentRound}
                            rounds={rounds}
                            isDrawing={isDrawing}
                            roundStart={roundStart}
                            gameFinished={gameFinished !== null}
                            word={selectedWord}
                            user={user}
                            ws={ws}
                            drawerUser={getDrawerUser()}
                            hide={lastWord != null}
                            gamePhase={gamePhase}
                            result={result}
                            users={users}
                            isObserver={isObserver}
                            soundEnabled={soundEnabled}
                            audioReveal={audioReveal}
                            audioSmallTick={audioSmallTick}
                            audioBlow={audioBlow}
                            audioFail={audioFail}
                        />
                        <LastWordScreen
                            lastWord={lastWord}
                            round={currentRound}
                            users={users}
                        />
                    </div>
                    <div
                        className={styles.rightMenu}
                        style={{
                            height: !calculatedSizes.isMobile
                                ? calculatedSizes.canvasHeight + 62
                                : 'auto',
                        }}
                    >
                        {guesses.length > 0 && (
                            <Votes
                                hasVoted={hasVoted}
                                myGuess={myGuess}
                                setHasVoted={setHasVoted}
                                guesses={guesses}
                                ws={ws}
                                user={user}
                                drawerName={drawer}
                                isObserver={isObserver}
                            />
                        )}
                        {isDrawing && guesses.length === 0 && (
                            <Tools
                                setSelectedTool={setSelectedTool}
                                selectedTool={selectedTool}
                                onEvent={onEvent}
                                thickness={thickness}
                                setThickness={setThickness}
                                setSelectedColor={setSelectedColor}
                                selectedColor={selectedColor}
                                hasUndo={hasUndo}
                            />
                        )}
                        <div
                            className={styles.chatArea}
                            style={{
                                height: !calculatedSizes.isMobile
                                    ? calculatedSizes.canvasHeight / 2
                                    : 'auto',
                                visibility:
                                    guesses.length > 0 ? 'hidden' : 'visible',
                            }}
                        >
                            <div className={styles.chat} ref={refChat}>
                                {messages.map((msg, msgIndex) => (
                                    <div
                                        key={msgIndex}
                                        className={classNames(
                                            msg.type === 'announcement' &&
                                                styles.chatAnnouncement
                                        )}
                                        style={msg.styles}
                                    >
                                        {msg.type === 'chat' && (
                                            <>
                                                {msg.name}: {msg.msg}
                                            </>
                                        )}
                                        {msg.type === 'announcement' && (
                                            <>{msg.msg}</>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={classNames(
                        styles.inputArea,
                        isDrawing && currentRound !== 0 && styles.isDrawing
                    )}
                    style={{ maxWidth: calculatedSizes.gameWidthMobile }}
                >
                    {gamePhase === 'drawing' && !isObserver && (
                        <Button
                            // className={styles.skip}
                            color='primary'
                            size='large'
                            onClick={onSubmitDrawing}
                            title='Submit drawing'
                            disabled={!isDrawing || !hasDrawn}
                        >
                            {isDrawing ? 'Submit drawing' : 'Drawing sent'}
                        </Button>
                    )}
                    {gamePhase === 'guessing' &&
                        drawer !== user.name &&
                        !isObserver && (
                            <>
                                <input
                                    ref={refChatInput}
                                    disabled={isDrawing || hasGuessed}
                                    type='text'
                                    placeholder={getPlaceholderText()}
                                    value={message}
                                    onKeyDown={onKeyDown}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                                <button
                                    className={styles.clearText}
                                    onClick={onEraseText}
                                    title='Clear text'
                                    style={{
                                        opacity: message.length > 0 ? 1 : 0,
                                    }}
                                >
                                    <svg
                                        aria-hidden='true'
                                        focusable='false'
                                        data-prefix='fal'
                                        data-icon='times'
                                        role='img'
                                        xmlns='http://www.w3.org/2000/svg'
                                        viewBox='0 0 320 512'
                                    >
                                        <path
                                            fill='currentColor'
                                            d='M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z'
                                        />
                                    </svg>
                                </button>
                            </>
                        )}
                </div>
            </div>
            <Adsense
                adFormat='horizontal'
                responsive
                notClickable={isMouseDown}
            />
            <div className={styles.modals}>
                <DrawingsModal
                    showDrawingsModal={showDrawingsModal}
                    setShowDrawingsModal={setShowDrawingsModal}
                />
                <Modal
                    isVisible={reportDrawer != null}
                    title='Report drawing'
                    actions={
                        <>
                            <button
                                className='button'
                                type='button'
                                onClick={onReportDrawer}
                            >
                                Confirm
                            </button>
                            <button
                                className='button'
                                type='button'
                                onClick={() => setReportDrawer(null)}
                            >
                                Cancel
                            </button>
                        </>
                    }
                >
                    <p>
                        You are only allowed to report drawings that break the
                        rules, such as if the player is drawing letters or
                        offensive art. When you report a drawing it will also
                        vote kick the player out of this room.
                    </p>
                    <p>
                        Are you sure you want to report{' '}
                        <strong>{reportDrawer || ''}</strong>?
                    </p>
                </Modal>
            </div>
        </>
    );
};

export default EveryoneDraws;
