// extracted by mini-css-extract-plugin
export var isMobile = "index-module--isMobile--9rmrl";
export var voteContainer = "index-module--voteContainer--+VAvf";
export var editContainer = "index-module--editContainer--tGoli";
export var btnCustomizeAvatar = "index-module--btnCustomizeAvatar--esQg1";
export var avatarContainer = "index-module--avatarContainer--Tma5e";
export var avatarSelector = "index-module--avatarSelector--5FsYu";
export var avatar = "index-module--avatar--E8JUh";
export var room = "index-module--room--qmYmE";
export var inputArea = "index-module--inputArea--RfS43";
export var isDrawing = "index-module--isDrawing--uQbdV";
export var skip = "index-module--skip--PlBKN";
export var btnHint = "index-module--btnHint--KUTmE";
export var clearText = "index-module--clearText--cmFVQ";
export var btnReportDrawer = "index-module--btnReportDrawer--kZ9Bk";
export var game = "index-module--game--3CCcw";
export var leftMenu = "index-module--leftMenu--S04Sc";
export var leftMenuActions = "index-module--leftMenuActions--cMhse";
export var btnSound = "index-module--btnSound--0WQSb";
export var userlist = "index-module--userlist--JjDSB";
export var viewport = "index-module--viewport--LR-oO";
export var nav = "index-module--nav--twUz-";
export var hint = "index-module--hint--CXeqA";
export var isLongWord = "index-module--isLongWord--QrrLu";
export var hasWord = "index-module--hasWord--RuPra";
export var rightMenu = "index-module--rightMenu--EnFhj";
export var chatArea = "index-module--chatArea--GXm8Y";
export var chat = "index-module--chat--21fTc";
export var chatAnnouncement = "index-module--chatAnnouncement--OAYsL";
export var adContainer = "index-module--adContainer--t99-B";