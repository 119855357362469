// extracted by mini-css-extract-plugin
export var announcement = "index-module--announcement--izkrP";
export var isVisible = "index-module--isVisible--nhozZ";
export var winnerText = "index-module--winnerText--Qpv8e";
export var avatar = "index-module--avatar--LEL06";
export var avatarMoving = "index-module--avatarMoving--sBuIa";
export var result = "index-module--result--A55pL";
export var most = "index-module--most--N3yMg";
export var isCorrect = "index-module--isCorrect--Whm-P";
export var players = "index-module--players--ZhghB";
export var funExtraLine = "index-module--funExtraLine--wh4Mv";
export var isMe = "index-module--isMe--LSny-";
export var skip = "index-module--skip--Lp4tf";
export var fullScreen = "index-module--fullScreen--SJQBm";
export var notFullScreen = "index-module--notFullScreen--VTCnW";
export var hint = "index-module--hint--h+QmZ";
export var showResult = "index-module--showResult--Knf4u";