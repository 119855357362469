import React from 'react';
import classNames from 'classnames';
import {
    tools as styleTools,
    colors as styleColors,
    isSelectedColor as styleIsSelectedColor,
    toolSelected as styleToolSelected,
    colorsContainer as styleColorsContainer,
    sizeContainer as styleSizeContainer,
    lineTool as styleLineTool,
} from './index.module.scss';

const Colors = [
    { title: 'White', color: '#FFFFFF' },
    { title: 'Light gray', color: '#DCDCDC' },
    { title: 'Gray', color: '#8C8C8C' },
    { title: 'Dark gray', color: '#303030' },
    { title: 'Black', color: '#000000' },
    { title: 'Light red', color: '#EF9A9A' },
    { title: 'Red', color: '#FF0000' },
    { title: 'Dark red', color: '#8B0000' },
    { title: 'Brown', color: '#A52A2A' },
    { title: 'Dark brown', color: '#8B4513' },
    { title: 'Peach', color: '#EEBB99' },
    { title: 'Pink', color: '#FF69B4' },
    { title: 'Dark pink', color: '#FF1493' },
    { title: 'Purple', color: '#9A12B3' },
    { title: 'Light purple', color: '#B39DDB' },
    { title: 'Light orange', color: '#FFCC80' },
    { title: 'Orange', color: '#FFA500' },
    { title: 'Dark orange', color: '#FF8C00' },
    { title: 'Light yellow', color: '#FFFF99' },
    { title: 'Yellow', color: '#FFFF00' },
    { title: 'Dark green', color: '#047806' },
    { title: 'Green', color: '#00FF00' },
    { title: 'Light green', color: '#A5D6A7' },
    { title: 'Light blue', color: '#BBDEFB' },
    { title: 'Sky blue', color: '#05B0FF' },
    { title: 'Blue', color: '#0000FF' },
    { title: 'Dark blue', color: '#00008B' },
    // { title: 'Blue grey', color: '#607D8B' },
    // { title: 'Light blue grey', color: '#B0BEC5' },
];

interface Props {
    setSelectedTool: React.Dispatch<React.SetStateAction<string>>;
    selectedTool: string;
    hasUndo: boolean;
    onEvent: (type: string) => void;
    thickness: number;
    setThickness: React.Dispatch<React.SetStateAction<number>>;
    setSelectedColor: React.Dispatch<React.SetStateAction<string>>;
    selectedColor: string;
}

const Tools = ({
    setSelectedTool,
    setSelectedColor,
    setThickness,
    onEvent,
    selectedColor,
    selectedTool,
    hasUndo,
    thickness,
}: Props) => {

    React.useEffect(() => {
        document.addEventListener('keydown', onUndoShortcut);

        return () => {
            document.removeEventListener('keydown', onUndoShortcut);
        }
    }, [hasUndo, onEvent])

    const onThicknessChange = (e: any) => {
        setThickness(Math.max(e.target.value, 1));
    };

    const onUndoShortcut = (e: any) => {
        if (e.ctrlKey && e.key === 'z' && hasUndo) {
            onEvent('undo')
        }
    };

    return (
        <div className={styleTools}>
            <button
                onClick={() => setSelectedTool('pencil')}
                title='Pencil'
                className={classNames(
                    selectedTool === 'pencil' && styleToolSelected
                )}
            >
                <svg
                    aria-hidden='true'
                    focusable='false'
                    data-prefix='fal'
                    data-icon='pen'
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'
                >
                    <path
                        fill='currentColor'
                        d='M493.25 56.26l-37.51-37.51C443.25 6.25 426.87 0 410.49 0s-32.76 6.25-45.26 18.74L12.85 371.12.15 485.34C-1.45 499.72 9.88 512 23.95 512c.89 0 1.78-.05 2.69-.15l114.14-12.61 352.48-352.48c24.99-24.99 24.99-65.51-.01-90.5zM126.09 468.68l-93.03 10.31 10.36-93.17 263.89-263.89 82.77 82.77-263.99 263.98zm344.54-344.54l-57.93 57.93-82.77-82.77 57.93-57.93c6.04-6.04 14.08-9.37 22.63-9.37 8.55 0 16.58 3.33 22.63 9.37l37.51 37.51c12.47 12.48 12.47 32.78 0 45.26z'
                    ></path>
                </svg>
            </button>
            <button
                onClick={() => setSelectedTool('circle')}
                title='Circle'
                className={classNames(
                    selectedTool === 'circle' && styleToolSelected
                )}
            >
                <svg
                    aria-hidden='true'
                    focusable='false'
                    data-prefix='fal'
                    data-icon='circle'
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'
                >
                    <path
                        fill='currentColor'
                        d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216z'
                    ></path>
                </svg>
            </button>
            <button
                onClick={() => setSelectedTool('box')}
                title='Box'
                className={classNames(
                    selectedTool === 'box' && styleToolSelected
                )}
            >
                <svg
                    aria-hidden='true'
                    focusable='false'
                    data-prefix='fal'
                    data-icon='square'
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                >
                    <path
                        fill='currentColor'
                        d='M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm16 400c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V80c0-8.8 7.2-16 16-16h352c8.8 0 16 7.2 16 16v352z'
                    ></path>
                </svg>
            </button>
            <button
                onClick={() => setSelectedTool('line')}
                title='Line'
                className={classNames(
                    selectedTool === 'line' && styleToolSelected,
                    styleLineTool
                )}
            >
                <svg
                    aria-hidden='true'
                    focusable='false'
                    data-prefix='fal'
                    data-icon='horizontal-rule'
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 640 512'
                >
                    <path
                        fill='currentColor'
                        d='M640 247.5v16a8 8 0 0 1-8 8H8a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h624a8 8 0 0 1 8 8z'
                    />
                </svg>
            </button>
            <button
                onClick={() => setSelectedTool('fill')}
                title='Fill area'
                className={classNames(
                    selectedTool === 'fill' && styleToolSelected
                )}
            >
                <svg
                    aria-hidden='true'
                    focusable='false'
                    data-prefix='fal'
                    data-icon='fill-drip'
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 576 512'
                >
                    <path
                        fill='currentColor'
                        d='M512 320s-64 92.65-64 128c0 35.35 28.66 64 64 64s64-28.65 64-64-64-128-64-128zm0 160c-17.64 0-32-14.36-32-31.96.26-9.78 13.57-37.67 32.01-68.73 18.43 31.04 31.73 58.91 31.99 68.69 0 17.64-14.36 32-32 32zm-9.37-262.94L294.94 9.37C288.69 3.12 280.5 0 272.31 0s-16.38 3.12-22.62 9.37l-92.85 92.85L56.97 2.35c-3.12-3.12-8.19-3.12-11.31 0L34.34 13.66c-3.12 3.12-3.12 8.19 0 11.31l99.88 99.88-106.1 106.1c-37.49 37.49-37.49 98.26 0 135.75l117.19 117.19c18.75 18.74 43.31 28.12 67.87 28.12 24.57 0 49.13-9.37 67.87-28.12l221.57-221.57c12.5-12.5 12.5-32.76.01-45.26zm-244.2 244.2C246.34 473.34 230.27 480 213.18 480s-33.16-6.66-45.24-18.74l-117.2-117.2c-6.88-6.88-11.77-15.14-14.91-24.06h363.85L258.43 461.26zM431.68 288H33.06c2.2-12.96 8.2-24.94 17.69-34.43l106.09-106.11 87.85 87.85c6.25 6.25 16.38 6.25 22.62 0 6.25-6.25 6.25-16.38 0-22.62l-87.85-87.85L272.29 32h.02L480 239.68 431.68 288z'
                    ></path>
                </svg>
            </button>
            <button
                onClick={() => setSelectedTool('eraser')}
                title='Eraser'
                className={classNames(
                    selectedTool === 'eraser' && styleToolSelected
                )}
            >
                <svg
                    aria-hidden='true'
                    focusable='false'
                    data-prefix='fal'
                    data-icon='eraser'
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'
                >
                    <path
                        fill='currentColor'
                        d='M497.94 273.94a48 48 0 0 0 0-67.88l-160-160a48 48 0 0 0-67.88 0l-256 256a48 48 0 0 0 0 67.88l96 96A48 48 0 0 0 144 480h356a12 12 0 0 0 12-12v-8a12 12 0 0 0-12-12H323.88l174.06-174.06zM292.69 68.69a16 16 0 0 1 22.62 0l160 160a16 16 0 0 1 0 22.62L358.63 368 176 185.37zM144 448a15.88 15.88 0 0 1-11.31-4.69l-96-96a16 16 0 0 1 0-22.62L153.37 208 336 390.63l-52.69 52.68A15.88 15.88 0 0 1 272 448z'
                    ></path>
                </svg>
            </button>
            <button onClick={() => onEvent('clear')} title='Clear'>
                <svg
                    aria-hidden='true'
                    focusable='false'
                    data-prefix='fal'
                    data-icon='trash-alt'
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                >
                    <path
                        fill='currentColor'
                        d='M296 432h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zm-160 0h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zM440 64H336l-33.6-44.8A48 48 0 0 0 264 0h-80a48 48 0 0 0-38.4 19.2L112 64H8a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h24v368a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V96h24a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8zM171.2 38.4A16.1 16.1 0 0 1 184 32h80a16.1 16.1 0 0 1 12.8 6.4L296 64H152zM384 464a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16V96h320zm-168-32h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8z'
                    ></path>
                </svg>
            </button>
            <button
                onClick={() => onEvent('undo')}
                disabled={!hasUndo}
                title='Undo'
            >
                <svg
                    aria-hidden='true'
                    focusable='false'
                    data-prefix='fal'
                    data-icon='undo'
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'
                >
                    <path
                        fill='currentColor'
                        d='M20 8h10c6.627 0 12 5.373 12 12v110.625C85.196 57.047 165.239 7.715 256.793 8.001 393.18 8.428 504.213 120.009 504 256.396 503.786 393.181 392.834 504 256 504c-63.926 0-122.202-24.187-166.178-63.908-5.113-4.618-5.354-12.561-.482-17.433l7.069-7.069c4.503-4.503 11.749-4.714 16.482-.454C150.782 449.238 200.935 470 256 470c117.744 0 214-95.331 214-214 0-117.744-95.331-214-214-214-82.862 0-154.737 47.077-190.289 116H180c6.627 0 12 5.373 12 12v10c0 6.627-5.373 12-12 12H20c-6.627 0-12-5.373-12-12V20c0-6.627 5.373-12 12-12z'
                    ></path>
                </svg>
            </button>
            <div className={styleColorsContainer}>
                <div className={styleColors}>
                    {Colors.map((color) => (
                        <button
                            key={color.color}
                            title={color.title}
                            onClick={() => setSelectedColor(color.color)}
                            disabled={selectedColor === color.color}
                            style={{ backgroundColor: color.color }}
                            className={classNames(
                                color.color === selectedColor &&
                                    styleIsSelectedColor
                            )}
                        />
                    ))}
                </div>
            </div>
            <div
                className={styleSizeContainer}
                style={{
                    opacity: selectedTool === 'fill' ? 0 : 1,
                }}
            >
                <input
                    type='range'
                    value={thickness}
                    step={2}
                    min={1}
                    max={23}
                    onChange={onThicknessChange}
                    title='Size'
                />
                <div>
                    <span
                        style={{
                            // width: 1,
                            // height: 1,
                            // transform: `scale(${thickness})`,
                            width: thickness,
                            height: thickness,
                            backgroundColor: selectedColor,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default React.memo(Tools);
