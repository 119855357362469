import classNames from 'classnames';
import React from 'react';
import { Dimension, UserType } from '../../types';
import UserAvatar from '../../../../features/UserAvatar';
import * as styles from './index.module.scss';

interface Props {
    lastWord: string | null;
    round: number;
    users: UserType[];
}

const LastWordScreen = ({ lastWord, round, users }: Props) => {
    const [currentWinner, setCurrentWinner] = React.useState<UserType | null>(
        null
    );

    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        if (lastWord != null) {
            if (users.length > 0) {
                setCurrentWinner(users[0]);
            }

            setTimeout(() => {
                setShow(true);
            }, 10);

            setTimeout(() => {
                setShow(false);
            }, 2800);
        } else {
            // setShow(false);
        }
    }, [lastWord]);

    return (
        <div className={classNames(styles.lastWordScreen, show && styles.show)}>
            <div>
                {lastWord != null && (
                    <>
                        <p>The word was</p>
                        <h3>
                            <strong>{lastWord}</strong>
                        </h3>
                    </>
                )}

                {currentWinner != null && currentWinner.score > 0 && (
                    <div>
                        <p>
                            <small>
                                <strong>{currentWinner.name}</strong> is
                                in the first place with{' '}
                                {currentWinner.score} points!
                            </small>
                        </p>
                        <UserAvatar
                            user={currentWinner}
                            className={styles.avatar}
                        />
                    </div>
                )}

                {/* <small>Round {round} ended</small> */}
            </div>
        </div>
    );
};

export default LastWordScreen;
